import React, { ChangeEvent, useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
    getCostPerOccasionString,
    getNumberOfOccasionsByTargetGroup,
    getTotalNumberOfOccasionsFromSelection,
    updateNumberOfOccasionsByTargetGroup,
    getCostForEvent,
    getSelectionCommentByTargetGroup,
    getApplicationSelectionColorByTargetGroup,
    getAdjustedAgeTagsByTargetGroup
} from "../../common/applicationUtils";
import Application from "../../types/Application";
import { AgeTag, HexColor, TargetGroup } from "../../common/enums";
import { toGroupedAgeTags } from "../../common/stringUtils";

type SelectionTableRowProps = {
    application: Application;
    targetGroup: string;
    onError: (errorMessage: string) => void;
    onApplicationChange: (application: Application) => void;
    onShowApplicationDetailsModal: (application: Application) => void;
    isCulturalManagement: boolean;
};

function SelectionTableRow({
    application,
    targetGroup,
    onError,
    onApplicationChange,
    onShowApplicationDetailsModal,
    isCulturalManagement
}: SelectionTableRowProps) {
    const [numberOfOccasions, setNumberOfOccasions] = useState(
        getNumberOfOccasionsByTargetGroup(application, targetGroup, isCulturalManagement).toString()
    );

    const commentPreview = useMemo(() => {
        const commentByTargetGroup = getSelectionCommentByTargetGroup(
            application,
            targetGroup as TargetGroup,
            isCulturalManagement
        );

        const commentPreviewMaxLength = 256;
        if (commentByTargetGroup.length > commentPreviewMaxLength) {
            return commentByTargetGroup.slice(0, commentPreviewMaxLength) + "...";
        }

        return commentByTargetGroup;
    }, [application, targetGroup, isCulturalManagement]);

    const rowHexColor: HexColor | undefined = useMemo(() => {
        const applicationSelectionColor = getApplicationSelectionColorByTargetGroup(
            application,
            targetGroup as TargetGroup,
            isCulturalManagement
        );

        return applicationSelectionColor?.hexColor;
    }, [application]);

    async function numberOfOccasionsChangeHandler(e: ChangeEvent<HTMLInputElement>) {
        let newNumberOfOccasions: number;

        if (e.target.value === "") {
            newNumberOfOccasions = 0;
        } else {
            newNumberOfOccasions = Number.parseInt(e.target.value);
        }

        setNumberOfOccasions(e.target.value);

        const updatedApplication = updateNumberOfOccasionsByTargetGroup(
            application,
            targetGroup,
            newNumberOfOccasions,
            isCulturalManagement
        );
        onApplicationChange(updatedApplication);

        const requestOptions: RequestInit = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                RequestVerificationToken: (
                    document.getElementsByName("__RequestVerificationToken")[0] as HTMLInputElement
                ).value
            }
        };

        const requestResult = await fetch(
            `/api/application/${application.id}/numberOfOccasions/${targetGroup}/${newNumberOfOccasions}?isCulturalManagement=${isCulturalManagement}`,
            requestOptions
        );

        if (!requestResult.ok) {
            onError(
                "Något gick fel när antalet evenemang uppdaterades. Vänligen uppdatera sidan och försök igen. Kontakta support om problemet kvarstår."
            );
        }
    }

    function rowClickedHandler(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        if (!(event.target instanceof HTMLTableCellElement)) return;

        onShowApplicationDetailsModal(application);
    }

    const totalNumberOfOccasions = getTotalNumberOfOccasionsFromSelection(application, isCulturalManagement);

    const adjustmentsByCurrentTargetGroup = application.adjustedSelectionAgeTags.filter(
        (adj) => adj.adjustedIn === targetGroup && adj.isCulturalManagement === isCulturalManagement
    );

    return (
        <tr onClick={rowClickedHandler} style={{ cursor: "pointer", backgroundColor: rowHexColor }}>
            <td>{application.actorName}</td>
            <td>
                {application.document.hasOperationalSupport && (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Evenemanget har verksamhetsstöd från Göteborgs kulturnämnd</Tooltip>}
                    >
                        <i className="bi bi-shield-fill-check me-1"></i>
                    </OverlayTrigger>
                )}
                {application.markedAsNewAt && (
                    <OverlayTrigger placement="top" overlay={<Tooltip>Evenemanget har markerats som nytt</Tooltip>}>
                        <i className="bi bi-star-fill text-warning me-1"></i>
                    </OverlayTrigger>
                )}
                {application.markedAsPremiereAt && (
                    <OverlayTrigger placement="top" overlay={<Tooltip>Evenemanget har markerats som premiär</Tooltip>}>
                        <i className="bi bi-award-fill text-primary me-1"></i>
                    </OverlayTrigger>
                )}
                {application.markedAsFinancedExternallyAt && (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Evenemanget har markerats som externt finansierad</Tooltip>}
                    >
                        <i className="bi bi-currency-dollar text-success me-1"></i>
                    </OverlayTrigger>
                )}
                {commentPreview && (
                    <OverlayTrigger placement="top" overlay={<Tooltip>{commentPreview}</Tooltip>}>
                        <i className="bi bi-chat-right-text-fill me-1"></i>
                    </OverlayTrigger>
                )}
                <span className="ms-1">{application.document.eventName}</span>
            </td>
            <td className="text-end">{application.document.maxNumberOfSeats}</td>
            <td className="text-end">
                {application.adjustedOccasionPrice === 0 || application.adjustedOccasionPrice ? (
                    <OverlayTrigger placement="top" overlay={<Tooltip>Evenemangets pris har justerats</Tooltip>}>
                        <i className="bi bi-pencil-fill me-1"></i>
                    </OverlayTrigger>
                ) : null}
                {getCostPerOccasionString(application).toLocaleString()}{" "}
            </td>
            <td>{application.document.tags.join(", ")}</td>
            <td>
                {adjustmentsByCurrentTargetGroup.length > 0 && (
                    <OverlayTrigger placement="top" overlay={<Tooltip>Evenemangets målgrupper har justerats</Tooltip>}>
                        <i className="bi bi-pencil-fill me-1"></i>
                    </OverlayTrigger>
                )}
                {application.groupedAgeTags.join(", ")}
            </td>
            {targetGroup != TargetGroup.Total && (
                <td>
                    <input
                        className="form-control"
                        type="number"
                        min={0}
                        value={numberOfOccasions}
                        onChange={numberOfOccasionsChangeHandler}
                    />
                </td>
            )}
            <td>
                <input className="form-control" type="number" disabled value={totalNumberOfOccasions} />
            </td>
            <td>
                <input
                    className="form-control"
                    type="text"
                    disabled
                    value={getCostForEvent(
                        application,
                        getTotalNumberOfOccasionsFromSelection(application, isCulturalManagement)
                    ).toLocaleString()}
                />
            </td>
        </tr>
    );
}

export default SelectionTableRow;
