export const weekDayNames = ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"];

export const monthNames = [
    "Januari",
    "Februari",
    "Mars",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "Augusti",
    "September",
    "Oktober",
    "November",
    "December"
];

export function isSameDay(date1: Date, date2: Date) {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}

export function getWeekDayName(date: Date) {
    return weekDayNames[date.getDay()];
}

export function getCaptionText(date: Date) {
    return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
}

export function getMonthsBetween(from: Date, to: Date): Date[] {
    const monthsBetween: Date[] = [];

    for (let i = 0; i <= to.getMonth() - from.getMonth(); i++) {
        const month = new Date(from);
        month.setMonth(from.getMonth() + i);

        monthsBetween.push(month);
    }

    return monthsBetween;
}

export function setDateTimeFromString(date: Date, time: string): Date {
    let timeArray = time.split(":");

    const hours = Number.parseInt(timeArray[0]);
    const minutes = Number.parseInt(timeArray[1]);

    const resultDate = new Date(date);

    resultDate.setHours(hours);
    resultDate.setMinutes(minutes);

    return resultDate;
}

export function formatTime(time: string): string {
    let timeArray = time.split(":");

    if (timeArray.length > 2) {
        timeArray = timeArray.slice(0, 2);
    }

    const formattedTime = timeArray.join(":");

    return formattedTime;
}

export function getTimeStringFromDate(date: Date): string {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
}

export function getTimeSpanString(date1: Date, date2: Date): string {
    const time1 = getTimeStringFromDate(date1);
    const time2 = getTimeStringFromDate(date2);

    return `${time1} - ${time2}`;
}

export function getWeekNumber(date: Date): number {
    const dowOffset = 1;
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    let day = firstDayOfYear.getDay() - dowOffset;
    day = day >= 0 ? day : day + 7;
    const dayNumber =
        Math.floor(
            (date.getTime() -
                firstDayOfYear.getTime() -
                (date.getTimezoneOffset() - firstDayOfYear.getTimezoneOffset()) * 60000) /
                86400000
        ) + 1;
    let weekNumber;
    if (day < 4) {
        weekNumber = Math.floor((dayNumber + day - 1) / 7) + 1;
        if (weekNumber > 52) {
            const nYear = new Date(date.getFullYear() + 1, 0, 1);
            let nDay = nYear.getDay() - dowOffset;
            nDay = nDay >= 0 ? nDay : nDay + 7;
            weekNumber = nDay < 4 ? 1 : 53;
        }
    } else {
        weekNumber = Math.floor((dayNumber + day - 1) / 7);
    }
    return weekNumber;
}

export function removeTimeFromDate(date: Date): Date {
    return new Date(date.toDateString());
}

export function toDateOnlyString(date: Date): string {
    const dateOnlyString = date.toLocaleDateString()
        .split(" ")[0];

    return dateOnlyString
}

export function toDayAndMonthString(date: Date): string {
    const dayAndMonthString = `${date.getDate()}/${date.getMonth() + 1}`

    return dayAndMonthString
}

export function getDateRange(from: Date, to: Date): Date[] {
    const range: Date[] = [];

    const timeDiff = to.getTime() - from.getTime()
    const nDays = Math.round(timeDiff / (1000 * 3600 * 24)) - 1;

    for (let dayIndex = 0; dayIndex < nDays; dayIndex++) {
        const date = new Date(from);
        date.setDate(date.getDate() + dayIndex);

        range.push(date);
    }

    return range;
}
