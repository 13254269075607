import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
    applicationBelongsToTargetGroup,
    getNumberOfOccasionsByTargetGroup,
    getTotalNumberOfOccasionsFromSelection
} from "../../common/applicationUtils";
import { Tag, TargetGroup } from "../../common/enums";
import Application from "../../types/Application";

type GenreChartProps = {
    applications: Application[];
    targetGroup: string;
    isCulturalManagement: boolean;
};

const chartColors = [
    "#56AEE2",
    "#E256AE",
    "#E25668",
    "#8A56E2",
    "#56E2CF",
    "#5668E2",
    "#CF56E2",
    "#56E289",
    "#E28956",
    "#E2CF56",
    "#AEE256",
    "#68E256"
];

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        }
    },
    scales: {
        y: {
            title: {
                display: true,
                text: "Antal tillfällen",
                font: {
                    size: 14,
                    weight: "bold"
                }
            }
        },
        x: {
            title: {
                display: true,
                text: "Konstformer",
                font: {
                    size: 14,
                    weight: "bold"
                }
            }
        }
    }
};

function GenreChart({ applications, targetGroup, isCulturalManagement }: GenreChartProps) {
    const [genreCount, setGenreCount] = useState([]);

    useEffect(() => {
        const genreCount = {
            [Tag.Nycirkus]: 0,
            [Tag.Dans]: 0,
            [Tag.RörligBild]: 0,
            [Tag.Konst]: 0,
            [Tag.Litteratur]: 0,
            [Tag.Musik]: 0,
            [Tag.Performance]: 0,
            [Tag.Slöjd]: 0,
            [Tag.Teater]: 0
        };

        applications
            .filter((a) => applicationBelongsToTargetGroup(a, targetGroup as TargetGroup, isCulturalManagement))
            .forEach((a) => {
                if (targetGroup === TargetGroup.Total) {
                    a.document.tags.forEach(
                        (t) => (genreCount[t as Tag] += getTotalNumberOfOccasionsFromSelection(a, isCulturalManagement))
                    );
                } else {
                    a.document.tags.forEach(
                        (t) =>
                            (genreCount[t as Tag] += getNumberOfOccasionsByTargetGroup(
                                a,
                                targetGroup,
                                isCulturalManagement
                            ))
                    );
                }
            });

        const newData = [
            genreCount[Tag.Nycirkus],
            genreCount[Tag.Dans],
            genreCount[Tag.RörligBild],
            genreCount[Tag.Konst],
            genreCount[Tag.Litteratur],
            genreCount[Tag.Musik],
            genreCount[Tag.Performance],
            genreCount[Tag.Slöjd],
            genreCount[Tag.Teater]
        ];

        setGenreCount(newData);
    }, [applications]);

    const data = {
        labels: [
            Tag.Nycirkus,
            Tag.Dans,
            Tag.RörligBild,
            Tag.Konst,
            Tag.Litteratur,
            Tag.Musik,
            Tag.Performance,
            Tag.Slöjd,
            Tag.Teater
        ],
        datasets: [
            {
                label: "Antal tillfällen",
                data: genreCount,
                backgroundColor: chartColors,
                borderColor: chartColors,
                borderWidth: 1
            }
        ]
    };

    return <Bar options={options} data={data} />;
}

export default GenreChart;
