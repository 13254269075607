import React from "react";
import Application from "../../types/Application";
import { getNumberOfOccasionsByTargetGroup, getTotalNumberOfOccasionsFromSelection } from "../../common/applicationUtils";
import { Accordion } from "react-bootstrap";
import { TargetGroup } from "../../common/enums";

type SelectionOverviewAccordionProps = {
    applications: Application[];
    isCulturalManagement: boolean;
};

function SelectionOverviewAccordion({ applications, isCulturalManagement }: SelectionOverviewAccordionProps) {
    const tableRows = applications.map((a) => (
        <tr key={a.id}>
            <td>{a.document.eventName}</td>
            <td className="text-end">
                {getNumberOfOccasionsByTargetGroup(a, TargetGroup.PreSchool, isCulturalManagement)}
            </td>
            <td className="text-end">
                {getNumberOfOccasionsByTargetGroup(a, TargetGroup.SchoolLower, isCulturalManagement)}
            </td>
            <td className="text-end">
                {getNumberOfOccasionsByTargetGroup(a, TargetGroup.SchoolMiddle, isCulturalManagement)}
            </td>
            <td className="text-end">
                {getNumberOfOccasionsByTargetGroup(a, TargetGroup.SchoolUpper, isCulturalManagement)}
            </td>
            <td className="text-end">
                {getNumberOfOccasionsByTargetGroup(a, TargetGroup.HighSchool, isCulturalManagement)}
            </td>
            <td className="text-end">
                {getNumberOfOccasionsByTargetGroup(a, TargetGroup.SpecialNeedsSchool, isCulturalManagement)}
            </td>
            <td className="text-end">
                {getNumberOfOccasionsByTargetGroup(a, TargetGroup.Other, isCulturalManagement)}
            </td>
            <td className="text-end">{getTotalNumberOfOccasionsFromSelection(a, isCulturalManagement)}</td>
        </tr>
    ));

    return (
        <div className="d-flex justify-content-center mb-3">
            <Accordion className="ms-5 me-5 w-100">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Tillfällen per målgrupp</Accordion.Header>
                    <Accordion.Body className="p-0">
                        <table className="table table-striped accordion-table selection-overview-table m-0">
                            <thead>
                                <tr>
                                    <th>Evenemang</th>
                                    <th className="text-end">{isCulturalManagement ? "KF förskola" : "Förskola"}</th>
                                    <th className="text-end">
                                        {isCulturalManagement ? "KF lågstadium" : "Lågstadium"}
                                    </th>
                                    <th className="text-end">
                                        {isCulturalManagement ? "KF mellanstadium" : "Mellanstadium"}
                                    </th>
                                    <th className="text-end">
                                        {isCulturalManagement ? "KF högstadium" : "Högstadium"}
                                    </th>
                                    <th className="text-end">{isCulturalManagement ? "KF gymnasium" : "Gymnasium"}</th>
                                    <th className="text-end">
                                        {isCulturalManagement ? "KF anpassad skola" : "Anpassad skola"}
                                    </th>
                                    <th className="text-end">
                                        {isCulturalManagement ? "KF extern budget" : "Extern budget"}
                                    </th>
                                    <th className="text-end">
                                        {isCulturalManagement ? "Kulturförvaltningen" : "Total"}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{tableRows}</tbody>
                        </table>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}

export default SelectionOverviewAccordion;
