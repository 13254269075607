import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import ApplicationPeriod from "../../../types/ApplicationPeriod";
import Application from "../../../types/Application";
import Site from "../../../types/Site";
import { SiteType } from "../../../common/enums";
import PlannedApplicationSite from "../../../types/PlannedApplicationSite";
import { formatTime, getTimeStringFromDate, isSameDay, setDateTimeFromString } from "../../../common/dateUtils";
import { getDefaultNumberOfSeats, hasTwoOccasions } from "../../../common/planningUtils";
import { Accordion, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import PlanningComment from "../../../types/PlanningComment";
import {
    confirmPlannedDate,
    deletePlanningComment,
    postPlanningComment,
    unconfirmPlannedDate
} from "../../../common/apiClient";
import PlanningCalendar from "./PlanningCalendar";
import { getAdjustedAgeTagsWithSelectedOccasions } from "../../../common/applicationUtils";
import { toGroupedAgeTags } from "../../../common/stringUtils";

type PlanningModalProps = {
    applicationPeriod: ApplicationPeriod;
    application: Application;
    site: Site;
    availableSiteDates: AvailableSiteDate[];
    availableSiteTime: AvailableSiteTime;
    plannedApplicationSites: PlannedApplicationSite[];
    onAddPlannedApplicationSite: (plannedApplicationSiteToAdd: PlannedApplicationSite) => void;
    onRemovePlannedApplicationSite: (plannedApplicationSiteToRemove: PlannedApplicationSite) => void;
    onPlannedApplicationSitesChange: (
        value:
            | PlannedApplicationSite[]
            | ((plannedApplicationSites: PlannedApplicationSite[]) => PlannedApplicationSite[])
    ) => void;
    show: boolean;
    onHide: () => void;
    planningComments: PlanningComment[];
    onPlanningCommentsChange: (
        value: PlanningComment[] | ((planningComments: PlanningComment[]) => PlanningComment[])
    ) => void;
    onError: (errorMesage: string) => void;
    canManagePlanning: boolean;
    defaultDate?: Date;
    blockedDates: Date[];
};

//
// DateMap[d] innehåller information om vad som ska renderas i kalendern på datum d
//
export type DateMap = { [date: string]: DateInfo };

export type DateInfo = {
    // Eventuell programläggning av det aktuella evenemanget på den aktuella spelplatsen på det aktuella datumet
    pas?: PlannedApplicationSite | undefined;

    // Eventuell kommentar bunden till det aktuella evenemanget, den aktuella spelplatsen och det valda datumet
    planningComment?: PlanningComment | undefined;

    // Indikerar om datumet är markerat som möjligt i det aktuella evenemangets intresseanmälan
    dateIsPossibleForEvent: boolean;

    // Indikerar om datumet är markerat som möjligt för den aktuella spelplatsen
    dateIsPossibleForSite: boolean;

    // Alla PAS för den aktuella spelplatsen och andra evenemang på det aktuella datumet
    otherEventsPlannedForSite: PlannedApplicationSite[];

    // Alla PAS för det akuella evenemanget och andra spelplatser på det aktuella datumet.
    otherSitesPlannedForApplication: PlannedApplicationSite[];

    // Alla PAS för det aktuella evenemangets aktör och andra evenemang på det aktuella datumet
    otherEventsPlannedForActor: PlannedApplicationSite[];

    // Om datumet har blockerats av KuBo
    dateIsBlocked: boolean;
};

const getDefaultDateInfo = (): DateInfo => ({
    dateIsPossibleForEvent: false,
    dateIsPossibleForSite: false,
    otherEventsPlannedForActor: [],
    otherEventsPlannedForSite: [],
    otherSitesPlannedForApplication: [],
    dateIsBlocked: false
});

function PlanningModal({
    application,
    applicationPeriod,
    site,
    availableSiteTime,
    availableSiteDates,
    plannedApplicationSites,
    onAddPlannedApplicationSite,
    onRemovePlannedApplicationSite,
    onPlannedApplicationSitesChange,
    show,
    onHide,
    planningComments,
    onPlanningCommentsChange,
    onError,
    canManagePlanning,
    defaultDate,
    blockedDates
}: PlanningModalProps) {
    const [twoOccasionsPlanned, setTwoOccasionsPlanned] = useState(false);
    const [occasion1StartsInput, setOccasion1StartsInput] = useState("");
    const [occasion1EndsInput, setOccasion1EndsInput] = useState("");
    const [occasion2StartsInput, setOccasion2StartsInput] = useState("");
    const [occasion2EndsInput, setOccasion2EndsInput] = useState("");
    const [selectedDate, setSelectedDate] = useState<Date | undefined>();
    const [numberOfSeatsInput, setNumberOfSeatsInput] = useState("");
    const [intendedAudienceInput, setIntendedAudienceInput] = useState("");
    const [dateMap, setDateMap] = useState<DateMap>();
    const [commentInput, setCommentInput] = useState("");
    const [commentIsSaved, setCommentIsSaved] = useState(false);

    const plannedApplicationSitesForApplicationAndSite =
        site && application
            ? plannedApplicationSites.filter((pas) => pas.siteId === site.id && pas.applicationId === application.id)
            : [];

    const plannedApplicationSiteForSelectedDate =
        selectedDate && application && site
            ? plannedApplicationSitesForApplicationAndSite.find((a) => isSameDay(selectedDate, a.occasion1Starts))
            : undefined;

    const selectedDateKey = selectedDate && selectedDate.toDateString();
    const selectedDateInfo = dateMap && selectedDate && dateMap[selectedDateKey];

    const adjustedAgeTags = application ? getAdjustedAgeTagsWithSelectedOccasions(application, false) : [];
    const groupedAgeTags = toGroupedAgeTags(adjustedAgeTags).join(", ");

    useEffect(() => {
        setCommentInput(selectedDateInfo?.planningComment?.comment ?? "");
        setIntendedAudienceInput(selectedDateInfo?.pas?.intendedAudience ?? groupedAgeTags);
        setCommentIsSaved(false);

        setNumberOfSeatsInput(
            (plannedApplicationSiteForSelectedDate &&
            (plannedApplicationSiteForSelectedDate.numberOfSeats ||
                plannedApplicationSiteForSelectedDate.numberOfSeats === 0)
                ? plannedApplicationSiteForSelectedDate.numberOfSeats
                : getDefaultNumberOfSeats(application, site)
            ).toString()
        );

        if (!plannedApplicationSiteForSelectedDate) return;

        setOccasion1StartsInput(getTimeStringFromDate(plannedApplicationSiteForSelectedDate.occasion1Starts));
        setOccasion1EndsInput(getTimeStringFromDate(plannedApplicationSiteForSelectedDate.occasion1Ends));

        const twoOccasionsArePlanned = hasTwoOccasions(plannedApplicationSiteForSelectedDate);
        setTwoOccasionsPlanned(twoOccasionsArePlanned);

        if (twoOccasionsArePlanned) {
            setOccasion2StartsInput(getTimeStringFromDate(plannedApplicationSiteForSelectedDate.occasion2Starts));
            setOccasion2EndsInput(getTimeStringFromDate(plannedApplicationSiteForSelectedDate.occasion2Ends));
        }
    }, [selectedDate]);

    useEffect(() => {
        if (!show || !application || !site || !plannedApplicationSites || !availableSiteDates) return;

        const map: DateMap = {};

        plannedApplicationSites.forEach((pas) => {
            const key = pas.occasion1Starts.toDateString();

            if (!map[key]) {
                map[key] = getDefaultDateInfo();
            }

            if (pas.applicationId === application.id && pas.siteId === site.id) {
                map[key].pas = pas;

                return;
            }

            if (pas.applicationId === application.id && pas.siteId !== site.id) {
                map[key].otherSitesPlannedForApplication.push(pas);
            }

            if (pas.application.actorName === application.actorName && pas.applicationId !== application.id) {
                map[key].otherEventsPlannedForActor.push(pas);
            }

            if (pas.siteId === site.id && pas.applicationId !== application.id) {
                map[key].otherEventsPlannedForSite.push(pas);
            }
        });

        availableSiteDates.forEach((asd) => {
            const key = asd.availableDate.toDateString();

            if (!map[key]) {
                map[key] = { ...getDefaultDateInfo(), dateIsPossibleForSite: true };
            } else {
                map[key].dateIsPossibleForSite = true;
            }
        });

        application.document.possibleOccasionDates.forEach((pod) => {
            const key = pod.toDateString();

            if (!map[key]) {
                map[key] = { ...getDefaultDateInfo(), dateIsPossibleForEvent: true };
            } else {
                map[key].dateIsPossibleForEvent =
                    map[key].otherSitesPlannedForApplication.length === 0 &&
                    map[key].otherEventsPlannedForActor.length === 0 &&
                    !map[key].pas;
            }
        });

        planningComments.forEach((pc) => {
            if (pc.applicationId !== application.id || pc.siteId !== site.id) return;

            const key = pc.date.toDateString();

            if (!map[key]) {
                map[key] = { ...getDefaultDateInfo(), planningComment: pc };
            } else {
                map[key].planningComment = pc;
            }
        });

        blockedDates.forEach((d) => {
            const key = d.toDateString();

            if (!map[key]) {
                map[key] = getDefaultDateInfo();
            }

            map[key].dateIsBlocked = true;
        });

        setDateMap(map);
        setNumberOfSeatsInput(getDefaultNumberOfSeats(application, site).toString());
    }, [show]);

    useEffect(() => {
        if (defaultDate) {
            setSelectedDate(defaultDate);
        }
    }, [defaultDate]);

    const dateClickHandler = useCallback((clickedDate: Date) => {
        setSelectedDate(clickedDate);
    }, []);

    if (!site || !application) {
        return <></>;
    }

    const twoOccasionsCheckedHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setTwoOccasionsPlanned(event.target.checked);
    };

    const submitPlannedApplicationSiteHandler = async () => {
        const occasion1Starts = setDateTimeFromString(selectedDate, occasion1StartsInput);
        const occasion1Ends = setDateTimeFromString(selectedDate, occasion1EndsInput);

        const numberOfSeats = Number.parseInt(numberOfSeatsInput);

        const plannedApplicationSite: PlannedApplicationSite = {
            id: 0,
            siteId: site.id,
            site: site,
            applicationId: application.id,
            application: application,
            occasion1Starts: occasion1Starts,
            occasion1Ends: occasion1Ends,
            dateIsConfirmed: false,
            numberOfSeats: numberOfSeats,
            intendedAudience: intendedAudienceInput
        };

        if (twoOccasionsPlanned) {
            const occasion2Starts = setDateTimeFromString(selectedDate, occasion2StartsInput);
            const occasion2Ends = setDateTimeFromString(selectedDate, occasion2EndsInput);

            plannedApplicationSite.occasion2Starts = occasion2Starts;
            plannedApplicationSite.occasion2Ends = occasion2Ends;
        }

        const updatedDateInfo: DateInfo = dateMap[selectedDateKey]
            ? { ...dateMap[selectedDateKey], pas: plannedApplicationSite, dateIsPossibleForEvent: false }
            : { ...getDefaultDateInfo(), pas: plannedApplicationSite };

        const updatedMap: DateMap = {
            ...dateMap,
            [selectedDateKey]: updatedDateInfo
        };

        setDateMap(updatedMap);

        await onAddPlannedApplicationSite(plannedApplicationSite);
    };

    const removePlannedApplicationSiteHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const key = selectedDate.toDateString();
        setDateMap((prevMap) => ({
            ...prevMap,
            [key]: {
                ...prevMap[key],
                pas: null,
                dateIsPossibleForEvent:
                    prevMap[key].otherSitesPlannedForApplication.length === 0 &&
                    prevMap[key].otherEventsPlannedForActor.length === 0 &&
                    application.document.possibleOccasionDates.some((d) => isSameDay(d, selectedDate))
            }
        }));

        await onRemovePlannedApplicationSite(plannedApplicationSiteForSelectedDate);
    };

    const commentInputChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCommentIsSaved(false);
        setCommentInput(event.target.value);
    };

    const savePlanningCommentClickHandler = async () => {
        const key = selectedDate.toDateString();
        const prevPlanningComment = dateMap[key]?.planningComment;

        if (!commentInput && !prevPlanningComment) return;

        if (!commentInput) {
            setDateMap((prevValue) => ({
                ...prevValue,
                [key]: { ...prevValue[key], planningComment: undefined }
            }));
            onPlanningCommentsChange((prevValue) => prevValue.filter((pc) => pc.id !== prevPlanningComment.id));

            const deleteResult = await deletePlanningComment(prevPlanningComment.id);

            if (deleteResult.errorMessage) {
                console.error(deleteResult.errorMessage);
                onError("Något gick fel när kommentaren sparades. Vänligen ladda om sidan och försök igen.");
            }

            setCommentIsSaved(true);

            return;
        }

        const tempId = -1;
        const planningComment: PlanningComment = {
            id: tempId,
            applicationId: application.id,
            siteId: site.id,
            comment: commentInput,
            date: selectedDate
        };

        setDateMap((prevValue) => ({
            ...prevValue,
            [key]: prevValue[key]
                ? { ...prevValue[key], planningComment: planningComment }
                : { ...getDefaultDateInfo(), planningComment: planningComment }
        }));

        onPlanningCommentsChange((prevValue) =>
            prevPlanningComment
                ? [...prevValue.filter((pc) => pc.id !== prevPlanningComment.id), planningComment]
                : [...prevValue, planningComment]
        );

        const postResult = await postPlanningComment(planningComment);

        if (postResult.errorMessage) {
            console.error(postResult.errorMessage);
            onError("Något gick fel när kommentaren sparades. Vänligen ladda om sidan och försök igen.");
            return;
        }

        const planningCommentWithId = { ...planningComment, id: postResult.value };

        setDateMap((prevValue) => ({
            ...prevValue,
            [key]: { ...prevValue[key], planningComment: planningCommentWithId }
        }));

        onPlanningCommentsChange((prevValue) => prevValue.map((pc) => (pc.id === tempId ? planningCommentWithId : pc)));

        setCommentIsSaved(true);
    };

    const confirmDateClickHandler = async () => {
        const updatedPAS: PlannedApplicationSite = { ...plannedApplicationSiteForSelectedDate, dateIsConfirmed: true };

        onPlannedApplicationSitesChange((prevValue) => [
            ...prevValue.filter((pas) => pas.id !== plannedApplicationSiteForSelectedDate.id),
            updatedPAS
        ]);

        const result = await confirmPlannedDate(plannedApplicationSiteForSelectedDate.id);

        if (result.errorMessage) {
            console.error(result.errorMessage);
            onError("Något gick fel när datumet markerades som bekräftat.");
            return;
        }
    };

    const unconfirmDateClickHandler = async () => {
        const updatedPAS: PlannedApplicationSite = { ...plannedApplicationSiteForSelectedDate, dateIsConfirmed: false };

        onPlannedApplicationSitesChange((prevValue) => [
            ...prevValue.filter((pas) => pas.id !== plannedApplicationSiteForSelectedDate.id),
            updatedPAS
        ]);

        const result = await unconfirmPlannedDate(plannedApplicationSiteForSelectedDate.id);

        if (result.errorMessage) {
            console.error(result.errorMessage);
            onError("Något gick fel när datumet markerades som ej bekräftat.");
            return;
        }
    };

    const hideHandler = () => {
        setSelectedDate(null);
        onHide();
    };

    const intendedAudienceInputKeyDownHandler = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === "Tab" && !intendedAudienceInput) {
            event.preventDefault();
            setIntendedAudienceInput(groupedAgeTags);
        }
    };

    const numberOfSeatsInputKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Tab" && !numberOfSeatsInput) {
            event.preventDefault();
            setNumberOfSeatsInput(getDefaultNumberOfSeats(application, site).toString());
        }
    };

    const submitButtonEnabled =
        numberOfSeatsInput &&
        intendedAudienceInput &&
        !!(
            (twoOccasionsPlanned &&
                occasion1StartsInput &&
                occasion1EndsInput &&
                occasion2StartsInput &&
                occasion2EndsInput) ||
            (!twoOccasionsPlanned && occasion1StartsInput && occasion1EndsInput)
        );

    const inputsDisabled = !!plannedApplicationSiteForSelectedDate || !canManagePlanning;

    let buildTime = "";
    let strikeTime = "";

    const siteType = SiteType[site.siteType as keyof typeof SiteType];

    if (siteType === SiteType.Library) {
        buildTime = application.document.library_BuildTime;
        strikeTime = application.document.library_StrikeTime;
    } else if (siteType === SiteType.Outdoors) {
        buildTime = application.document.outdoors_BuildTime;
        strikeTime = application.document.outdoors_StrikeTime;
    } else if (siteType === SiteType.School) {
        buildTime = application.document.school_BuildTime;
        strikeTime = application.document.school_StrikeTime;
    }

    return (
        <Modal show={show} size="xl" onHide={hideHandler}>
            <Modal.Header closeButton>
                <h4>
                    Programläggning av evenemang {application.document.eventName} på spelplats {site.name}
                </h4>
            </Modal.Header>
            <Modal.Body>
                <div className="p-0">
                    <div className="d-flex planning-modal__calendar-container">
                        <PlanningCalendar
                            applicationPeriod={applicationPeriod}
                            dateMap={dateMap}
                            onDateClicked={dateClickHandler}
                            selectedDate={selectedDate}
                            defaultDate={defaultDate}
                        />

                        <div className="w-100 ps-3 d-flex flex-column justify-content-between">
                            {selectedDate && (
                                <>
                                    <div>
                                        <h4>Programläggning {selectedDate.toLocaleDateString()}</h4>
                                        {selectedDateInfo && selectedDateInfo.dateIsBlocked ? (
                                            <div className="alert alert-danger">Det valda datumet är blockerat</div>
                                        ) : null}
                                        <div>
                                            {canManagePlanning && (
                                                <div className="mb-1">
                                                    <input
                                                        className="form-check-input me-2"
                                                        type="checkbox"
                                                        checked={twoOccasionsPlanned}
                                                        onChange={twoOccasionsCheckedHandler}
                                                        disabled={inputsDisabled}
                                                    />
                                                    <label className="form-check-label">
                                                        Programlägg två tillfällen
                                                    </label>
                                                </div>
                                            )}
                                            {twoOccasionsPlanned &&
                                            (plannedApplicationSiteForSelectedDate || canManagePlanning) ? (
                                                <div className="mb-1">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label className="form-label mb-0">
                                                                Första tillfället startar
                                                            </label>
                                                            <input
                                                                type="time"
                                                                className="form-control"
                                                                value={occasion1StartsInput}
                                                                onChange={(e) =>
                                                                    setOccasion1StartsInput(e.target.value)
                                                                }
                                                                disabled={inputsDisabled}
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="form-label mb-0">
                                                                Första tillfället slutar
                                                            </label>
                                                            <input
                                                                type="time"
                                                                className="form-control"
                                                                value={occasion1EndsInput}
                                                                onChange={(e) => setOccasion1EndsInput(e.target.value)}
                                                                disabled={inputsDisabled}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label className="form-label mb-0">
                                                                Andra tillfället startar
                                                            </label>
                                                            <input
                                                                type="time"
                                                                className="form-control"
                                                                value={occasion2StartsInput}
                                                                onChange={(e) =>
                                                                    setOccasion2StartsInput(e.target.value)
                                                                }
                                                                disabled={inputsDisabled}
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="form-label mb-0">
                                                                Andra tillfället slutar
                                                            </label>
                                                            <input
                                                                type="time"
                                                                className="form-control"
                                                                value={occasion2EndsInput}
                                                                onChange={(e) => setOccasion2EndsInput(e.target.value)}
                                                                disabled={inputsDisabled}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                (plannedApplicationSiteForSelectedDate || canManagePlanning) && (
                                                    <div className="row mb-1">
                                                        <div className="col-md-6">
                                                            <label className="form-label mb-0">
                                                                Tillfället startar
                                                            </label>
                                                            <input
                                                                type="time"
                                                                className="form-control"
                                                                value={occasion1StartsInput}
                                                                onChange={(e) =>
                                                                    setOccasion1StartsInput(e.target.value)
                                                                }
                                                                disabled={inputsDisabled}
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="form-label mb-0">Tillfället slutar</label>
                                                            <input
                                                                type="time"
                                                                className="form-control"
                                                                value={occasion1EndsInput}
                                                                onChange={(e) => setOccasion1EndsInput(e.target.value)}
                                                                disabled={inputsDisabled}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div>
                                            <label className="form-label mb-0">
                                                <span className="me-1">Målgrupper</span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            Standardvärdet är målgrupperna som angavs av aktören, med de
                                                            eventuella justeringar som gjordes i Urvalet
                                                        </Tooltip>
                                                    }
                                                >
                                                    <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                                                </OverlayTrigger>
                                            </label>
                                            <textarea
                                                className="form-control"
                                                rows={2}
                                                placeholder={groupedAgeTags}
                                                value={intendedAudienceInput}
                                                onChange={(e) => setIntendedAudienceInput(e.target.value)}
                                                onKeyDown={intendedAudienceInputKeyDownHandler}
                                                disabled={inputsDisabled}
                                            ></textarea>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-md-6">
                                                <label className="form-label mb-0">
                                                    <span className="me-1">Antal sittplatser</span>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                Standardvärdet är det lägre av det maximala antalet
                                                                platser som aktören angav i sin intresseanmälan och det
                                                                maximala antalet platser som angavs för spelplatsen
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i
                                                            className="bi bi-info-circle-fill"
                                                            data-bs-toggle="tooltip"
                                                        ></i>
                                                    </OverlayTrigger>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={numberOfSeatsInput}
                                                    onChange={(e) => setNumberOfSeatsInput(e.target.value)}
                                                    onKeyDown={numberOfSeatsInputKeyDownHandler}
                                                    placeholder={getDefaultNumberOfSeats(application, site).toString()}
                                                    disabled={inputsDisabled}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            {plannedApplicationSiteForSelectedDate && canManagePlanning ? (
                                                <>
                                                    {plannedApplicationSiteForSelectedDate.dateIsConfirmed ? (
                                                        <button
                                                            className="btn btn-danger me-2"
                                                            onClick={unconfirmDateClickHandler}
                                                        >
                                                            <i className="bi bi-x me-1"></i>
                                                            Markera som datum som ej bekräftat
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-primary me-2"
                                                            onClick={confirmDateClickHandler}
                                                        >
                                                            <i className="bi bi-check me-1"></i>
                                                            Markera datum som bekräftat
                                                        </button>
                                                    )}
                                                    <button
                                                        onClick={removePlannedApplicationSiteHandler}
                                                        className="btn btn-danger"
                                                    >
                                                        Ta bort tillfälle
                                                    </button>
                                                </>
                                            ) : (
                                                canManagePlanning && (
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={submitPlannedApplicationSiteHandler}
                                                        disabled={!submitButtonEnabled}
                                                    >
                                                        Programlägg tillfälle
                                                    </button>
                                                )
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <label className="form-label mb-0">Kommentar</label>
                                        <textarea
                                            className="form-control mb-2"
                                            rows={4}
                                            value={commentInput}
                                            onChange={commentInputChangeHandler}
                                            disabled={!canManagePlanning}
                                        ></textarea>
                                        <div className="d-flex justify-content-end">
                                            {canManagePlanning && commentIsSaved ? (
                                                <button className="btn btn-success">Kommentaren har sparats</button>
                                            ) : canManagePlanning &&
                                              !commentInput &&
                                              !dateMap[selectedDate.toDateString()]?.planningComment ? (
                                                <button className="btn btn-primary" disabled>
                                                    Spara kommentar
                                                </button>
                                            ) : (
                                                canManagePlanning && (
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={savePlanningCommentClickHandler}
                                                    >
                                                        Spara kommentar
                                                    </button>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="position-relative" style={{ height: "64px" }}>
                        <Accordion>
                            <Accordion.Item eventKey="0" style={{ position: "absolute", left: 0, right: 0, bottom: 0 }}>
                                <Accordion.Body>
                                    <div className="mt-4 p-2">
                                        <div className="row mb-2">
                                            <div className="col-6">
                                                <label className="form-label mb-0">Spelplatsen tillgänglig från</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={
                                                        availableSiteTime
                                                            ? formatTime(availableSiteTime.availableFrom)
                                                            : ""
                                                    }
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-6">
                                                <label className="form-label mb-0">Spelplatsen tillgänglig till</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={
                                                        availableSiteTime
                                                            ? formatTime(availableSiteTime.availableTo)
                                                            : ""
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-6">
                                                <label className="form-label mb-0">
                                                    Max antal platser för spelplatsen
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={site.numberOfSeats}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-6">
                                                <label className="form-label mb-0">
                                                    Max antal platser för evenemanget
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={application.document.maxNumberOfSeats}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-6">
                                                <label className="form-label mb-0">Byggtid</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={formatTime(buildTime)}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-6">
                                                <label className="form-label mb-0">Rivtid</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={formatTime(strikeTime)}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-6">
                                                <label className="form-label mb-0">Evenemangets längd</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={formatTime(application.document.eventDuration)}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Body>
                                <Accordion.Header>
                                    <span className="fw-bold">Information</span>
                                </Accordion.Header>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default PlanningModal;
