import React, { useEffect, useRef, useState } from "react";
import ApplicationPeriod from "../../types/ApplicationPeriod";
import Site from "../../types/Site";
import {
    fetchApplicationPeriod,
    fetchPlannedApplications,
    fetchAvailableSiteTimeBySiteIdAndApplicationPeriodId,
    fetchPlannedApplicationSitesBySiteIdAndApplicationPeriodId,
    fetchSiteById,
    sendInternalSiteAgreement
} from "../../common/apiClient";
import { pageLoadErrorMessage } from "../../common/errorMessages";
import PlannedApplicationSite from "../../types/PlannedApplicationSite";
import {
    defaultFontFamily,
    defaultMarginBottom,
    defaultTextStyles,
    sectionHeaderStyles,
    tableCellHeaderStyles,
    tableCellStyles,
    tableStyles
} from "../../common/inlineStyles";
import SiteScheduleTableRow from "./SiteScheduleTableRow";
import { copyRichText } from "../../common/stringUtils";
import SuccessMessage from "../Common/Messages/SuccessMessage";
import Swal from "sweetalert2";
import LoadingSpinner from "../Common/LoadingSpinner";
import ErrorMessage from "../Common/Messages/ErrorMessage";
import InfoMessage from "../Common/Messages/InfoMessage";
import { SiteType } from "../../common/enums";

type InternalSiteAgreementProps = {
    siteId: number;
    applicationPeriodId: number;
};

function InternalSiteAgreement({ siteId, applicationPeriodId }: InternalSiteAgreementProps) {
    const [applicationPeriod, setApplicationPeriod] = useState<ApplicationPeriod>();
    const [site, setSite] = useState<Site>();
    const [availableSiteTime, setAvailableSiteTime] = useState<AvailableSiteTime>();
    const [plannedApplicationSites, setPlannedApplicationSites] = useState<PlannedApplicationSite[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    const [featuresCommentInput, setFeaturesCommentInput] = useState("");
    const [generalCommentInput, setGeneralCommentInput] = useState("");

    const agreementRef = useRef<HTMLDivElement>();
    const successMessageRef = useRef<HTMLDivElement>();

    useEffect(() => {
        const fetchData = async () => {
            const [
                applicationPeriodFetchResult,
                siteFetchResult,
                availableSiteTimeFetchResult,
                plannedApplicationSitesFetchResult,
                applicationsFetchResult
            ] = await Promise.all([
                fetchApplicationPeriod(applicationPeriodId),
                fetchSiteById(siteId),
                fetchAvailableSiteTimeBySiteIdAndApplicationPeriodId(siteId, applicationPeriodId),
                fetchPlannedApplicationSitesBySiteIdAndApplicationPeriodId(siteId, applicationPeriodId),
                fetchPlannedApplications(siteId, applicationPeriodId)
            ]);

            if (applicationPeriodFetchResult.errorMessage) {
                console.error(applicationPeriodFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (siteFetchResult.errorMessage) {
                console.error(siteFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (availableSiteTimeFetchResult.errorMessage) {
                console.error(availableSiteTimeFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (plannedApplicationSitesFetchResult.errorMessage) {
                console.error(plannedApplicationSitesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (applicationsFetchResult.errorMessage) {
                console.error(applicationsFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            plannedApplicationSitesFetchResult.value.forEach((pas) => {
                const plannedApplication = applicationsFetchResult.value.find((a) => a.id === pas.applicationId);

                pas.application = plannedApplication;
                pas.site = siteFetchResult.value;
            });

            setApplicationPeriod(applicationPeriodFetchResult.value);
            setSite(siteFetchResult.value);
            setAvailableSiteTime(availableSiteTimeFetchResult.value);
            setPlannedApplicationSites(plannedApplicationSitesFetchResult.value);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (errorMessage) {
        return <ErrorMessage message={errorMessage} />;
    }

    const sendAgreementClickHandler = async () => {
        const result = await sendInternalSiteAgreement(siteId, applicationPeriodId, agreementRef.current.innerHTML);

        if (result.errorMessage) {
            Swal.fire({
                title: "Något gick fel när överenskommelsen skickades",
                text: "Vänligen uppdatera sidan och försök igen. Kontakta support om problemet kvarstår.",
                icon: "error"
            });
            return;
        }

        Swal.fire({
            title: "Överenskommelsen har skickats till " + site.contactPersonEmail,
            icon: "success"
        });
    };

    const copyToClipboardClickHandler = async () => {
        await copyRichText(agreementRef.current.innerHTML);

        if (!copiedToClipboard) {
            setCopiedToClipboard(true);
            setTimeout(() => {
                successMessageRef.current.classList.add("slide-out");
                setTimeout(() => {
                    setCopiedToClipboard(false);
                }, 80);
            }, 3000);
        }
    };
    const availableTechnology: string[] = [];

    if (site.hasPA) {
        availableTechnology.push("PA");
    }
    if (site.hasLighting) {
        availableTechnology.push("Ljus");
    }
    if (site.hasSound) {
        availableTechnology.push("Ljud");
    }
    if (site.hasBlackbox) {
        availableTechnology.push("Blackbox");
    }
    if (site.hasBlackout) {
        availableTechnology.push("Mörkläggning");
    }

    const scheduleTableRows: JSX.Element[] = plannedApplicationSites.map((pas) => (
        <SiteScheduleTableRow plannedApplicationSite={pas} availableSiteTime={availableSiteTime} key={pas.id} />
    ));

    return (
        <div className="mt-3">
            {copiedToClipboard ? (
                <div className="slide-alert" ref={successMessageRef}>
                    <SuccessMessage message="Överenskommelsen har kopierats!" />
                </div>
            ) : (
                <></>
            )}
            <h2 className="mb-4 d-flex justify-content-center">
                Överenskommelse: Lokal {site.name} {site.roomName}, spelperiod{" "}
                {applicationPeriod.occasionsFrom.toLocaleDateString()}
            </h2>

            <div className="m-auto" style={{ width: "800px" }}>
                <InfoMessage
                    message={`Här finns en överenskommelse mellan KuBo och den interna spelplatsen ${site.name} ${site.roomName}. 
                    Om du klickar på "Skicka till ${site.contactPersonEmail}", så skickas dokumentet till spelplatsens kontaktperson ${site.contactPersonName} 
                    (${site.contactPersonEmail}). För att arbeta vidare med överenskommelsen, klickar du på "Kopiera till urklipp" och 
                    klistrar in det i t.ex. Word.`}
                />

                <div className="mt-2 mb-2">
                    <label className="form-label">Kommentar om teknik</label>
                    <textarea
                        className="form-control"
                        value={featuresCommentInput}
                        onChange={(e) => setFeaturesCommentInput(e.target.value)}
                        cols={5}
                        rows={3}
                    ></textarea>
                </div>

                <div className="mt-2 mb-4">
                    <label className="form-label">Övrig kommentar/förtydligande</label>
                    <textarea
                        className="form-control"
                        value={generalCommentInput}
                        onChange={(e) => setGeneralCommentInput(e.target.value)}
                        cols={5}
                        rows={3}
                    ></textarea>
                </div>

                <div className="mb-2">
                    <button className="btn btn-primary me-2" onClick={sendAgreementClickHandler}>
                        <i className="bi bi-send me-1"></i>
                        Skicka till {site.contactPersonEmail}
                    </button>
                    <button className="btn btn-warning" onClick={copyToClipboardClickHandler}>
                        <i className="bi bi-clipboard me-1"></i>
                        Kopiera till urklipp
                    </button>
                </div>

                <div className="border rounded p-5" ref={agreementRef}>
                    <div style={{ margin: "auto", width: "700px" }}>
                        <div
                            style={{
                                marginBottom: defaultMarginBottom,
                                fontFamily: defaultFontFamily,
                                fontSize: "24pt"
                            }}
                        >
                            Spelplan KuBo för spelperioden {applicationPeriod.occasionsFrom.toLocaleDateString()}
                        </div>

                        <div style={defaultTextStyles}>Hej!</div>
                        <br />

                        <div style={defaultTextStyles}>
                            Här kommer nu all samlad information kring de aktörer och föreställningar som kommer spela
                            hos er. Det innehåller information om bland annat lokal, teknik, tider och kontaktuppgifter
                            till aktören. Detta för att ni ska kunna facilitera programmet med rätt förutsättningar. Ta
                            nu också gärna kontakt med aktören/rna i god tid så ni tillsammans kan planera och förbereda
                            era värdar som ska vara på plats under genomförandet.
                        </div>
                        <br />

                        <div style={defaultTextStyles}>
                            Har ni frågor om värdskapet eller KuBos hemsida hör vänligen av er. Tack för ert samarbete
                            som möjliggör rolig och god samverkan!
                        </div>
                        <br />

                        <div style={sectionHeaderStyles}>Spelplats</div>
                        <table style={tableStyles}>
                            <tbody>
                                <tr>
                                    <td style={{ width: "50%", ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Platsnamn</span> <br />
                                        {site.name}
                                    </td>
                                    <td style={{ width: "50%", ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Förvaltning</span> <br />
                                        Kulturförvaltningen
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />

                        <table style={tableStyles}>
                            <tbody>
                                <tr>
                                    <td style={{ ...tableCellStyles }} colSpan={2}>
                                        <span style={tableCellHeaderStyles}>Besöksadress</span> <br />
                                        {site.address}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "50%", ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Telefon till spelplats</span> <br />
                                        {site.phoneNumber}
                                    </td>
                                    <td style={{ width: "50%", ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Ansvarig för spelplatsen</span> <br />
                                        {site.contactPersonName}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ ...tableCellStyles }} colSpan={2}>
                                        <span style={tableCellHeaderStyles}>Typ av lokal/verksamhet</span> <br />
                                        {SiteType[site.siteType as keyof typeof SiteType]}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />

                        <div style={sectionHeaderStyles}>
                            Kontaktperson på spelplatsen för artister och praktiska frågor
                        </div>
                        <table style={tableStyles}>
                            <tbody>
                                <tr>
                                    <td style={{ width: "50%", ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Namn</span> <br />
                                        {site.contactPersonName}
                                    </td>
                                    <td style={{ width: "50%", ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Befattning</span> <br />
                                        {site.contactPersonPosition}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "50%%", ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Telefon</span> <br />
                                        {site.contactPersonPhoneNumber}
                                    </td>
                                    <td style={{ width: "50%%", ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>E-post</span> <br />
                                        {site.contactPersonEmail}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ ...tableCellStyles }} colSpan={2}>
                                        <span style={tableCellHeaderStyles}>Avser nyttjande av lokalen </span> <br />
                                        {site.roomName}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />

                        <div style={sectionHeaderStyles}>Avser följande speltider och föreställningar</div>
                        <table style={tableStyles}>
                            <thead>
                                <tr>
                                    <th style={{ ...tableCellStyles, minWidth: "5rem" }}>Datum</th>
                                    <th style={{ ...tableCellStyles, minWidth: "2rem" }}>Byggtid</th>
                                    <th style={{ ...tableCellStyles, minWidth: "2rem" }}>Rivtid</th>
                                    <th style={tableCellStyles}>Föreställning</th>
                                    <th style={tableCellStyles}>Fst 1</th>
                                    <th style={tableCellStyles}>Fst 2</th>
                                    <th style={tableCellStyles}>Aktör</th>
                                    <th style={tableCellStyles}>Aktörens kontaktuppgifter</th>
                                </tr>
                            </thead>
                            <tbody>{scheduleTableRows}</tbody>
                        </table>
                        <br />

                        <div style={sectionHeaderStyles}>Utrustning Spelplatsen/Värdscenen tillhandahåller</div>
                        <table style={tableStyles}>
                            <tbody>
                                <tr>
                                    <td style={{ ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Tekniker </span> <br />
                                        {availableTechnology.join(", ")}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Förtydligange om tekniker</span> <br />
                                        {site.otherInformation}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ ...tableCellStyles }} colSpan={1}>
                                        <span style={tableCellHeaderStyles}>Övriga befintliga faciliteter</span> <br />
                                        {site.otherFacilities}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />

                        {featuresCommentInput ? (
                            <>
                                <div style={sectionHeaderStyles}>Kommentar om tekniker</div>
                                <div style={defaultTextStyles}>{featuresCommentInput}</div>
                                <br />
                            </>
                        ) : (
                            <></>
                        )}

                        <div style={sectionHeaderStyles}>Kulturstöd tillhandahåller</div>
                        <ul>
                            <li style={defaultTextStyles}>
                                Inköp av publika konstupplevelser för scen så som musik, teater och dans
                            </li>
                            <li style={defaultTextStyles}>
                                Administration; avtal med aktörer samt hanteringen av bokningssidan
                            </li>
                            <li style={defaultTextStyles}>
                                Publikarbete riktat till förskolor och skolor genom bokningssidan KuBo samt i samverkan
                                med kultursamordnare på grundskole- respektive förskoleförvaltningen
                            </li>
                            <li style={defaultTextStyles}>
                                Erbjuder spelplatsen möjligheten till, i mån av plats, biljetter för offentlig publik
                            </li>
                            <li style={defaultTextStyles}>
                                Statistikuppföljning, analys och rapport kopplat till mål i Göteborg stads plan för barn
                                och ungkultur
                            </li>
                            <li style={defaultTextStyles}>Utveckling av bokningssidan</li>
                        </ul>

                        <div style={sectionHeaderStyles}>Spelplatsen/Värdcenen ska</div>
                        <ul>
                            <li style={defaultTextStyles}>
                                Tillhandahålla en städad och iordningställd lokal med tillgängliga sittplatser anpassade
                                efter arrangemangets behov.
                            </li>
                            <li style={defaultTextStyles}>
                                Ansvara för att facilitera arrangemanget och bistå med personal
                            </li>
                            <li style={defaultTextStyles}>Ta kontakt med aktörerna i god tid inför arrangemanget</li>
                            <li style={defaultTextStyles}>
                                Ta emot aktörerna och visa dem till rätta, är bärhjälp samt behjälplig vid bygg/riv
                            </li>
                            <li style={defaultTextStyles}>Tillhandahålla aktörerna kaffe, te/vatten</li>
                            <li style={defaultTextStyles}>Ta emot barn och elever</li>
                            <li style={defaultTextStyles}>
                                Ha rollen som publikvärd i KuBos bokningssida, göra närvarorapport, vid avvikelser i
                                bokningen.
                            </li>
                        </ul>

                        <div style={sectionHeaderStyles}>Ändring</div>
                        <div style={defaultTextStyles}>
                            Samtliga ändringar och tillägg till detta dokument ska göras skriftligen.
                        </div>
                        <br />

                        <div style={sectionHeaderStyles}>Antal personer</div>
                        <div style={defaultTextStyles}>
                            Antal besökare får ej överstiga det antal angivet i respektive lokal. Vid överträdelse kan
                            personal och/eller brandmyndigheterna stänga lokalen.
                        </div>

                        <br />
                        <div style={sectionHeaderStyles}>Ansvar vid stöld</div>
                        <div style={defaultTextStyles}>
                            Spelplatsen/Värdscenen ansvar inte för aktörens eller besökarnas kläder eller annan lös
                            egendom som förvaras i lokalen.
                        </div>
                        <br />

                        <div style={sectionHeaderStyles}>Kontaktpersoner på KuBo</div>
                        <table style={tableStyles}>
                            <tbody>
                                <tr>
                                    <td style={{ width: "33.33%", ...tableCellStyles }} colSpan={1}>
                                        Vendela Källström
                                    </td>
                                    <td style={{ width: "33.33%", ...tableCellStyles }} colSpan={1}>
                                        0764–95 04 14
                                    </td>
                                    <td style={{ width: "33.33%", ...tableCellStyles }} colSpan={1}>
                                        <a href="mailto:vendela.kallstrom@kultur.goteborg.se">
                                            vendela.kallstrom@kultur.goteborg.se
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />

                        <div style={sectionHeaderStyles}>Samverkan</div>
                        <div style={defaultTextStyles}>
                            Lokalen är en samverkan inom Kulturförvaltningen. Ingen lokalhyra/kostnad utgår.
                        </div>
                        <br />

                        {generalCommentInput ? (
                            <>
                                <div style={sectionHeaderStyles}>Kommentar/förtydligande</div>
                                <div style={defaultTextStyles}>{generalCommentInput}</div>
                                <br />
                            </>
                        ) : (
                            <></>
                        )}

                        <div style={defaultTextStyles}>
                            Med vänliga hälsningar, <br />
                            KuBo
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InternalSiteAgreement;
