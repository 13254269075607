import React, { useEffect, useState } from "react";
import Application from "../../../types/Application";
import Site from "../../../types/Site";
import { pageLoadErrorMessage } from "../../../common/errorMessages";
import ErrorMessage from "../../Common/Messages/ErrorMessage";
import LoadingSpinner from "../../Common/LoadingSpinner";
import ApplicationSiteSelectionTableRow from "./ApplicationSiteSelectionTableRow";
import {
    deletePlannedApplicationSite,
    fetchApplicationById,
    fetchApplicationPeriod,
    fetchApplicationsWithPlannedOccasions,
    fetchAvailableSiteDatesByApplicationPeriodId,
    fetchAvailableSiteTimesByApplicationPeriodId,
    fetchBlockedDatesByApplicationPeriodId,
    fetchPlannedApplicationSitesByApplicationPeriodId,
    fetchPlanningCommentsByApplicationPeriodId,
    fetchViableSitesByApplicationId,
    postPlannedApplicationSite
} from "../../../common/apiClient";
import ApplicationDetailsAccordionItem from "./ApplicationDetailsAccordionItem";
import ApplicationDatesAccordionItem from "./ApplicationDatesAccordionItem";
import ApplicationPeriod from "../../../types/ApplicationPeriod";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import PlanningModal from "../PlanningModal";
import PlannedApplicationSite from "../../../types/PlannedApplicationSite";
import { isSameDay } from "../../../common/dateUtils";
import {
    getAdjustedAgeTagsWithSelectedOccasions,
    getRemainingNumberOfOccasionsToPlan,
    getTotalNumberOfOccasionsFromSelection,
    numberOfOccasionsRemainingToPlanColor
} from "../../../common/applicationUtils";
import ApplicationSiteSelectionFilter, {
    ApplicationSiteSelectionFilterOptions
} from "./ApplicationSiteSelectionFilter";
import { applyApplicationSiteSelectionFilter } from "../../../common/siteUtils";
import { hasTwoOccasions, sortPlannedApplicationSites } from "../../../common/planningUtils";
import PlanningOverviewAccordionItem from "../PlanningOverviewAccordionItem";
import PlanningComment from "../../../types/PlanningComment";
import { toGroupedAgeTags } from "../../../common/stringUtils";

type ApplicationSiteSelectionProps = {
    applicationId: number;
    applicationPeriodId: number;
    canManagePlanning: boolean;
};

const accordionId = "accordion";

function ApplicationSiteSelection({
    applicationId,
    applicationPeriodId,
    canManagePlanning
}: ApplicationSiteSelectionProps) {
    const [application, setApplication] = useState<Application>();
    const [viableSites, setViableSites] = useState<Site[]>([]);
    const [applicationPeriod, setApplicationPeriod] = useState<ApplicationPeriod>();
    const [availableSiteDates, setAvailableSiteDates] = useState<AvailableSiteDate[]>([]);
    const [availableSiteTimes, setAvailableSiteTimes] = useState<AvailableSiteTime[]>([]);
    const [plannedApplicationSites, setPlannedApplicationSites] = useState<PlannedApplicationSite[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [modalSiteId, setModalSiteId] = useState(0);
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<ApplicationSiteSelectionFilterOptions>();
    const [showPlanningModal, setShowPlanningModal] = useState(false);
    const [planningComments, setPlanningComments] = useState<PlanningComment[]>([]);
    const [modalDefaultDate, setModalDefaultDate] = useState<Date>();
    const [blockedDates, setBlockedDates] = useState<Date[]>([])

    useEffect(() => {
        const fetchData = async () => {
            const [
                applicationFetchResult,
                plannedApplicationsFetchResult,
                sitesFetchResult,
                applicationPeriodFetchResult,
                availableSiteDatesFetchResult,
                availableSiteTimeFetchResult,
                plannedApplicationSitesFetchResult,
                planningCommentsFetchResult,
                blockedDatesFetchResult
            ] = await Promise.all([
                fetchApplicationById(applicationId),
                fetchApplicationsWithPlannedOccasions(applicationPeriodId),
                fetchViableSitesByApplicationId(applicationId),
                fetchApplicationPeriod(applicationPeriodId),
                fetchAvailableSiteDatesByApplicationPeriodId(applicationPeriodId),
                fetchAvailableSiteTimesByApplicationPeriodId(applicationPeriodId),
                fetchPlannedApplicationSitesByApplicationPeriodId(applicationPeriodId),
                fetchPlanningCommentsByApplicationPeriodId(applicationPeriodId),
                fetchBlockedDatesByApplicationPeriodId(applicationPeriodId)
            ]);

            if (applicationFetchResult.errorMessage) {
                console.error(applicationFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (sitesFetchResult.errorMessage) {
                console.error(sitesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (applicationPeriodFetchResult.errorMessage) {
                console.error(applicationPeriodFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (availableSiteDatesFetchResult.errorMessage) {
                console.error(availableSiteDatesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (availableSiteTimeFetchResult.errorMessage) {
                console.error(availableSiteTimeFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (plannedApplicationsFetchResult.errorMessage) {
                console.error(plannedApplicationsFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (plannedApplicationSitesFetchResult.errorMessage) {
                console.error(plannedApplicationSitesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (planningCommentsFetchResult.errorMessage) {
                console.error(planningCommentsFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (blockedDatesFetchResult.errorMessage) {
                console.error(blockedDatesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            const adjustedAgeTagsForApplication = getAdjustedAgeTagsWithSelectedOccasions(
                applicationFetchResult.value,
                false
            );
            applicationFetchResult.value.groupedAgeTags = toGroupedAgeTags(adjustedAgeTagsForApplication);

            plannedApplicationSitesFetchResult.value.forEach((pas) => {
                pas.site = sitesFetchResult.value.find((s) => s.id === pas.siteId);
                pas.application = plannedApplicationsFetchResult.value.find((a) => a.id === pas.applicationId);

                const adjustedAgeTags = getAdjustedAgeTagsWithSelectedOccasions(pas.application, false);
                pas.application.groupedAgeTags = toGroupedAgeTags(adjustedAgeTags);
            });

            sitesFetchResult.value.forEach((s) => {
                const availableSiteDates = availableSiteDatesFetchResult.value.filter((asd) => asd.siteId === s.id);
                s.availableSiteDates = availableSiteDates;
            });

            setApplication(applicationFetchResult.value);
            setViableSites(sitesFetchResult.value);
            setApplicationPeriod(applicationPeriodFetchResult.value);
            setAvailableSiteDates(availableSiteDatesFetchResult.value);
            setAvailableSiteTimes(availableSiteTimeFetchResult.value);
            setPlannedApplicationSites(sortPlannedApplicationSites(plannedApplicationSitesFetchResult.value));
            setPlanningComments(planningCommentsFetchResult.value);
            setBlockedDates(blockedDatesFetchResult.value);
            setIsLoading(false);

            const urlSearchParams = new URLSearchParams(window.location.search);
            const selectedDateQueryParam = urlSearchParams.get("selectedDate");
            const selectedSiteIdQueryParam = urlSearchParams.get("selectedSiteId");

            if (selectedSiteIdQueryParam) {
                const selectedSiteId = Number.parseInt(selectedSiteIdQueryParam);
                if (!sitesFetchResult.value.some((s) => s.id === selectedSiteId)) {
                    return;
                }

                setModalSiteId(selectedSiteId);

                if (selectedDateQueryParam) {
                    const selectedDate = new Date(selectedDateQueryParam);
                    setModalDefaultDate(selectedDate);
                    setShowPlanningModal(true);
                }
            }


        };
        fetchData();
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (errorMessage) {
        return <ErrorMessage message={errorMessage} />;
    }

    const tableRowClickHandler = (site: Site) => {
        setModalSiteId(site.id);
        setShowPlanningModal(true);
    };

    const hidePlanningModalHandler = () => {
        setShowPlanningModal(false);
        setModalDefaultDate(null);
    };

    const plannedApplicationSiteAddedHandler = async (addedPAS: PlannedApplicationSite) => {
        const temporaryId = 0;
        addedPAS.id = temporaryId;

        setPlannedApplicationSites((prevValues) => sortPlannedApplicationSites([...prevValues, addedPAS]));

        const plannedDateWasAvailableForSite = availableSiteDates.some(
            (asd) => asd.siteId === addedPAS.siteId && isSameDay(asd.availableDate, addedPAS.occasion1Starts)
        );

        setViableSites((prevValues) =>
            prevValues.map((s) =>
                s.id === addedPAS.siteId
                    ? {
                          ...s,
                          remainingNumberOfAvailableSiteDates: plannedDateWasAvailableForSite
                              ? s.remainingNumberOfAvailableSiteDates - 1
                              : s.remainingNumberOfAvailableSiteDates
                      }
                    : s
            )
        );

        const numberOfAddedOccasions = hasTwoOccasions(addedPAS) ? 2 : 1;
        setApplication((prevValue) => ({
            ...prevValue,
            numberOfPlannedOccasions: prevValue.numberOfPlannedOccasions + numberOfAddedOccasions
        }));

        const postResult = await postPlannedApplicationSite(addedPAS);

        if (postResult.errorMessage) {
            console.error(postResult.errorMessage);
            setErrorMessage(
                "Något gick fel när evenemanget skulle programläggas. Vänligen uppdatera sidan och försök igen. Kontakta support om problemet kvarstår."
            );
            return;
        }

        const postedPlannedApplicationSiteId = postResult.value;

        setPlannedApplicationSites((prevValues) =>
            prevValues.map((pas) => (pas.id === temporaryId ? { ...pas, id: postedPlannedApplicationSiteId } : pas))
        );
    };

    const plannedApplicationSiteRemovedHandler = async (removedPAS: PlannedApplicationSite) => {
        setPlannedApplicationSites((prevValues) =>
            prevValues.filter(
                (pas) =>
                    pas.siteId !== removedPAS.siteId ||
                    pas.applicationId !== removedPAS.applicationId ||
                    !isSameDay(pas.occasion1Starts, removedPAS.occasion1Starts)
            )
        );

        const plannedDateWasAvailableForSite = availableSiteDates.some(
            (asd) => asd.siteId === removedPAS.siteId && isSameDay(asd.availableDate, removedPAS.occasion1Starts)
        );

        setViableSites((prevValues) =>
            prevValues.map((s) =>
                s.id === removedPAS.siteId
                    ? {
                          ...s,
                          remainingNumberOfAvailableSiteDates: plannedDateWasAvailableForSite
                              ? s.remainingNumberOfAvailableSiteDates + 1
                              : s.remainingNumberOfAvailableSiteDates
                      }
                    : s
            )
        );

        const numberOfRemovedOccasions = hasTwoOccasions(removedPAS) ? 2 : 1;

        setApplication((prevValue) => ({
            ...prevValue,
            numberOfPlannedOccasions: prevValue.numberOfPlannedOccasions - numberOfRemovedOccasions
        }));

        const deleteResult = await deletePlannedApplicationSite(removedPAS);

        if (deleteResult.errorMessage) {
            console.error(deleteResult.errorMessage);
            setErrorMessage(
                "Något gick fel när det programlagda tillfället skulle tas bort. Vänligen uppdatera sidan och försök igen. Kontakta support om problemet kvarstår."
            );
        }
    };

    const selectedFilterOptionsChangeHandler = (
        updatedProperty: keyof ApplicationSiteSelectionFilterOptions,
        updatedValue: any
    ) => {
        setSelectedFilterOptions((prevValue) => ({ ...prevValue, [updatedProperty]: updatedValue }));
    };

    const overviewDateClickHandler = (clickedDate: Date, clickedRowSiteId: number, clickedRowApplicationId: number) => {
        setModalDefaultDate(clickedDate);
        setModalSiteId(clickedRowSiteId);
        setShowPlanningModal(true);
    };

    const plannedApplicationSitesForOverview = plannedApplicationSites.filter(
        (pas) => pas.applicationId === applicationId
    );

    const modalSite = modalSiteId ? viableSites.find((s) => s.id === modalSiteId) : undefined;

    const availableSiteDatesForModal = modalSiteId
        ? availableSiteDates.filter((asd) => asd.siteId === modalSiteId)
        : [];
    const availableSiteTimeForModal = modalSiteId
        ? availableSiteTimes.find((ast) => ast.siteId === modalSiteId)
        : undefined;

    const filteredSites = applyApplicationSiteSelectionFilter(viableSites, selectedFilterOptions);

    const viableSiteTableRows = filteredSites.map((s) => (
        <ApplicationSiteSelectionTableRow site={s} key={s.id} onTableRowClick={() => tableRowClickHandler(s)} />
    ));

    return (
        <>
            <div className="container mt-4">
                <div className="mb-4">
                    <h2>Programläggning: {application.document.eventName}</h2>
                </div>
                <div className="mb-4">
                    <Accordion>
                        <ApplicationDatesAccordionItem
                            application={application}
                            applicationPeriod={applicationPeriod}
                        />
                        <ApplicationDetailsAccordionItem application={application} />
                        <PlanningOverviewAccordionItem
                            plannedApplicationSites={plannedApplicationSitesForOverview}
                            planningComments={planningComments}
                            showExport={false}
                            applicationPeriod={applicationPeriod}
                            onDateClick={overviewDateClickHandler}
                        />
                    </Accordion>
                </div>
                <section className="mt-4">
                    <div className="d-flex mb-2">
                        <div className="me-2">
                            <label className="form-label mb-0">
                                <span className="me-1">Tillfällen totalt</span>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            Antalet tillfällen av evenemanget som valdes under urvalsprocessen.
                                        </Tooltip>
                                    }
                                >
                                    <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                                </OverlayTrigger>
                            </label>
                            <input
                                className="form-control text-end pe-0"
                                type="number"
                                value={getTotalNumberOfOccasionsFromSelection(application)}
                                disabled
                                style={{ width: "160px" }}
                            />
                        </div>
                        <div>
                            <label className="form-label mb-0">
                                <span className="me-1">Ej programlagda</span>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            Antalet tillfällen av evenemanget som fortfarande måste programläggas.
                                        </Tooltip>
                                    }
                                >
                                    <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                                </OverlayTrigger>
                            </label>
                            <input
                                className="form-control text-end pe-0"
                                type="number"
                                value={getRemainingNumberOfOccasionsToPlan(application)}
                                disabled
                                style={{ width: "160px", backgroundColor: numberOfOccasionsRemainingToPlanColor(application) }}
                            />
                        </div>
                    </div>
                    <ApplicationSiteSelectionFilter
                        selectedFilterOptions={selectedFilterOptions}
                        onSelectedFilterOptionsChange={selectedFilterOptionsChangeHandler}
                        applicationPeriod={applicationPeriod}
                    />
                    <div className="border rounded">
                        <table className="table table-hover-effect">
                            <thead>
                                <tr>
                                    <th>Plats</th>
                                    <th>Lokalnamn</th>
                                    <th>Matchning</th>
                                    <th style={{ width: "190px" }}>
                                        <span className="me-1">Tillgängliga datum</span>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    Det totala antalet datum som kan programläggas på spelplatsen.
                                                </Tooltip>
                                            }
                                        >
                                            <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                                        </OverlayTrigger>
                                    </th>
                                    <th style={{ width: "190px" }}>
                                        <span className="me-1">Ej programlagda</span>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    Det återstående antalet datum som kan programläggas på spelplatsen.
                                                </Tooltip>
                                            }
                                        >
                                            <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                                        </OverlayTrigger>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{viableSiteTableRows}</tbody>
                        </table>
                    </div>
                </section>
            </div>
            <PlanningModal
                site={modalSite}
                application={application}
                availableSiteDates={availableSiteDatesForModal}
                availableSiteTime={availableSiteTimeForModal}
                plannedApplicationSites={plannedApplicationSites}
                onAddPlannedApplicationSite={plannedApplicationSiteAddedHandler}
                onRemovePlannedApplicationSite={plannedApplicationSiteRemovedHandler}
                applicationPeriod={applicationPeriod}
                show={showPlanningModal}
                onHide={hidePlanningModalHandler}
                planningComments={planningComments}
                onPlanningCommentsChange={setPlanningComments}
                onError={setErrorMessage}
                canManagePlanning={canManagePlanning}
                onPlannedApplicationSitesChange={setPlannedApplicationSites}
                defaultDate={modalDefaultDate}
                blockedDates={blockedDates}
            />
        </>
    );
}

export default ApplicationSiteSelection;
