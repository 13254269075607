import React from "react";
import PlannedApplicationSite from "../../types/PlannedApplicationSite";
import { getTimeStringFromDate } from "../../common/dateUtils";
import { getNumberOfSeats, hasTwoOccasions } from "../../common/planningUtils";
import { tableCellStyles } from "../../common/inlineStyles";
import { getAdjustedAgeTagsWithSelectedOccasions } from "../../common/applicationUtils";
import { toGroupedAgeTags } from "../../common/stringUtils";

type OccasionScheduleTableRowProps = {
    plannedApplicationSite: PlannedApplicationSite;
};

function OccasionScheduleTableRow({ plannedApplicationSite }: OccasionScheduleTableRowProps) {
    const site = plannedApplicationSite.site;
    const application = plannedApplicationSite.application;

    const occasion1Starts = plannedApplicationSite.occasion1Starts;
    const occasion1Ends = plannedApplicationSite.occasion1Ends;
    const occasion2Starts = plannedApplicationSite.occasion2Starts;
    const occasion2Ends = plannedApplicationSite.occasion2Ends;

    const adjustedAgeTags = getAdjustedAgeTagsWithSelectedOccasions(application);
    const groupedAgeTags = toGroupedAgeTags(adjustedAgeTags);

    const numberOfSeats = getNumberOfSeats(plannedApplicationSite);

    return (
        <tr>
            <td style={tableCellStyles}>{site.name}</td>
            <td style={tableCellStyles}>{site.roomName}</td>
            <td style={{ ...tableCellStyles, textAlign: "end" }}>{numberOfSeats}</td>
            <td style={tableCellStyles}>{occasion1Starts.toLocaleDateString()}</td>
            <td style={{ ...tableCellStyles, textAlign: "end" }}>{plannedApplicationSite.occasionWeekNumber}</td>
            <td style={tableCellStyles}>{plannedApplicationSite.occasionWeekDay}</td>
            <td style={tableCellStyles}>{`${getTimeStringFromDate(occasion1Starts)}-${getTimeStringFromDate(
                occasion1Ends
            )}`}</td>
            <td style={tableCellStyles}>
                {hasTwoOccasions(plannedApplicationSite)
                    ? `${getTimeStringFromDate(occasion2Starts)}-${getTimeStringFromDate(occasion2Ends)}`
                    : ""}
            </td>
            <td style={tableCellStyles}>{application.document.eventName}</td>
            <td style={tableCellStyles}>{plannedApplicationSite.intendedAudience ?? groupedAgeTags.join(", ")}</td>
            <td style={{ ...tableCellStyles }}>
                <div>{site.contactPersonName}</div>
                <div>{site.contactPersonPhoneNumber}</div>
                <div>{site.contactPersonEmail}</div>
            </td>
        </tr>
    );
}

export default OccasionScheduleTableRow;
