import React, { useRef } from "react";
import { Button, DayPicker, DayProps, useDayRender } from "react-day-picker";
import { getCaptionText, getTimeSpanString, getWeekDayName, isSameDay } from "../../../common/dateUtils";
import ApplicationPeriod from "../../../types/ApplicationPeriod";
import { DateInfo, DateMap } from ".";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type PlanningCalendarProps = {
    onDateClicked: (clickedDate: Date) => void;
    applicationPeriod: ApplicationPeriod;
    selectedDate: Date;
    dateMap: DateMap;
    defaultDate?: Date;
};

const PlanningCalendar = React.memo(
    ({ onDateClicked, applicationPeriod, selectedDate, dateMap, defaultDate }: PlanningCalendarProps) => {
        return (
            <DayPicker
                className="DayPicker"
                mode="multiple"
                weekStartsOn={1}
                formatters={{
                    formatWeekdayName: getWeekDayName,
                    formatCaption: getCaptionText
                }}
                onDayClick={onDateClicked}
                defaultMonth={defaultDate ?? applicationPeriod.occasionsFrom}
                components={{
                    Day: ({ date, displayMonth }: DayProps) => (
                        <PlanningModalDateCell
                            date={date}
                            isSelected={selectedDate && isSameDay(date, selectedDate)}
                            displayMonth={displayMonth}
                            dateInfo={dateMap ? dateMap[date.toDateString()] : undefined}
                        />
                    )
                }}
            />
        );
    }
);

type PlanningModalDateCellProps = {
    date: Date;
    isSelected: boolean;
    displayMonth: Date;
    dateInfo: DateInfo | undefined;
};

function PlanningModalDateCell({ isSelected, displayMonth, dateInfo, date }: PlanningModalDateCellProps) {
    const buttonRef = useRef<HTMLButtonElement>();
    const dayRender = useDayRender(date, displayMonth, buttonRef);

    if (date.getMonth() !== displayMonth.getMonth()) return <></>;

    const onePlannedOccasion = dateInfo !== undefined && !!dateInfo.pas;
    const twoPlannedOccasions =
        dateInfo && dateInfo.pas && dateInfo.pas.occasion2Starts && dateInfo.pas.occasion2Starts.getFullYear() > 1;

    let numberOfOccasionsIndicators = <></>;

    if (twoPlannedOccasions) {
        numberOfOccasionsIndicators = (
            <>
                <div className="indicator"></div>
                <div className="indicator"></div>
            </>
        );
    } else if (onePlannedOccasion) {
        numberOfOccasionsIndicators = <div className="indicator"></div>;
    }

    const dayCellClassName = `planning-modal__calender-day-cell ${
        isSelected ? "selected" : ""
    }  w-100 h-100 d-flex flex-column`;

    return (
        <Button {...dayRender.buttonProps} style={{ borderRadius: 5 }}>
            <div className={dayCellClassName}>
                <div className="d-flex justify-content-between align-items-start h-100">
                    <div className="text-start pt-2 w-75">
                        {dateInfo && dateInfo.dateIsPossibleForSite && (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Datumet har markerats som möjligt av spelplatsen</Tooltip>}
                            >
                                <i className="bi bi-calendar-check-fill me-1 text-success"></i>
                            </OverlayTrigger>
                        )}
                        {dateInfo && dateInfo.dateIsPossibleForEvent && (
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>Datumet har markerats som möjligt i evenemangets intresseanmälan</Tooltip>
                                }
                            >
                                <i className="bi bi-person-check-fill me-1 text-primary"></i>
                            </OverlayTrigger>
                        )}
                        {dateInfo && dateInfo.otherSitesPlannedForApplication.length > 0 ? (
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Evenemanget är programlagt på annan plats:{" "}
                                        {dateInfo.otherSitesPlannedForApplication
                                            .map((pas) => pas.site.name)
                                            .join(", ")}{" "}
                                    </Tooltip>
                                }
                            >
                                <i className="bi bi-person-fill-dash me-1" style={{ color: "#DC3545" }}></i>
                            </OverlayTrigger>
                        ) : dateInfo && dateInfo.otherEventsPlannedForActor.length > 0 ? (
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Följande evenemang av{" "}
                                        {` ${dateInfo.otherEventsPlannedForActor[0].application.actorName} `}
                                        är programlagda på andra platser:{" "}
                                        {dateInfo.otherEventsPlannedForActor
                                            .map((pas) => pas.application.document.eventName)
                                            .join(", ")}
                                    </Tooltip>
                                }
                            >
                                <i className="bi bi-person-fill-dash me-1" style={{ color: "#F1AEB5" }}></i>
                            </OverlayTrigger>
                        ) : (
                            <></>
                        )}
                        {dateInfo && dateInfo.otherEventsPlannedForSite.length > 0 && (
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        <div>
                                            Följande andra evenemang är programlagda på{" "}
                                            {dateInfo.otherEventsPlannedForSite[0].site.name}:{" "}
                                            {dateInfo.otherEventsPlannedForSite
                                                .map((pas) => pas.application.document.eventName)
                                                .join(", ")}
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <i className="bi bi-calendar-x-fill me-1 text-warning"></i>
                            </OverlayTrigger>
                        )}
                        {dateInfo && dateInfo.dateIsBlocked && (
                            <OverlayTrigger placement="top" overlay={<Tooltip>Datumet är blockerat</Tooltip>}>
                                <i className="bi bi-x-lg me-1 text-danger"></i>
                            </OverlayTrigger>
                        )}
                    </div>
                    <div>
                        <span style={{ fontSize: 20 }}>{date.getDate()}</span>
                    </div>
                </div>
                {onePlannedOccasion || twoPlannedOccasions ? (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {twoPlannedOccasions ? (
                                    <>
                                        <div>
                                            {getTimeSpanString(
                                                dateInfo.pas.occasion1Starts,
                                                dateInfo.pas.occasion1Ends
                                            )}
                                        </div>
                                        <div>
                                            {getTimeSpanString(
                                                dateInfo.pas.occasion2Starts,
                                                dateInfo.pas.occasion2Ends
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <div>
                                        {getTimeSpanString(dateInfo.pas.occasion1Starts, dateInfo.pas.occasion1Ends)}
                                    </div>
                                )}
                            </Tooltip>
                        }
                    >
                        <div className="planning-modal__number-of-occasions-indicators h-50">
                            {numberOfOccasionsIndicators}
                        </div>
                    </OverlayTrigger>
                ) : (
                    <></>
                )}
            </div>
        </Button>
    );
}

export default PlanningCalendar;
