import React, { useEffect, useState } from "react";
import Application from "../../../types/Application";
import ApplicationPeriod from "../../../types/ApplicationPeriod";
import {
    applyApplicationPlanningFilter,
    getAdjustedAgeTagsWithSelectedOccasions,
    sortApplicationsByRemainingToPlan
} from "../../../common/applicationUtils";
import { pageLoadErrorMessage } from "../../../common/errorMessages";
import LoadingSpinner from "../../Common/LoadingSpinner";
import ErrorMessage from "../../Common/Messages/ErrorMessage";
import InfoMessage from "../../Common/Messages/InfoMessage";
import ApplicationTableRow from "./ApplicationTableRow";
import {
    deletePlannedApplicationSite,
    fetchApplicationPeriod,
    fetchAvailableSiteDatesByApplicationPeriodId,
    fetchBlockedDatesByApplicationPeriodId,
    fetchPlannedApplicationSitesByApplicationPeriodId,
    fetchPlanningCommentsByApplicationPeriodId,
    fetchSelectedApplicationsByApplicationPeriodId,
    fetchSitesWithPlannedOccasions,
    postPlannedApplicationSite
} from "../../../common/apiClient";
import PlannedApplicationSite from "../../../types/PlannedApplicationSite";
import ApplicationPlanningFilter, { ApplicationPlanningFilterOptions } from "./ApplicationPlanningFilter";
import PlanningOverviewAccordionItem from "../PlanningOverviewAccordionItem";
import PlanningComment from "../../../types/PlanningComment";
import { Accordion } from "react-bootstrap";
import { toGroupedAgeTags } from "../../../common/stringUtils";
import { hasTwoOccasions, sortPlannedApplicationSites } from "../../../common/planningUtils";
import PlanningModal from "../PlanningModal";
import Site from "../../../types/Site";
import { isSameDay } from "../../../common/dateUtils";

type ApplicationPlanningProps = {
    applicationPeriodId: number;
    canManagePlanning: boolean;
};

function ApplicationPlanning({ applicationPeriodId, canManagePlanning }: ApplicationPlanningProps) {
    const [applicationPeriod, setApplicationPeriod] = useState<ApplicationPeriod>();
    const [applications, setApplications] = useState<Application[]>([]);
    const [sites, setSites] = useState<Site[]>([]);
    const [plannedApplicationSites, setPlannedApplicationSites] = useState<PlannedApplicationSite[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<ApplicationPlanningFilterOptions>();
    const [planningComments, setPlanningComments] = useState<PlanningComment[]>([]);
    const [modalApplicationId, setModalApplicationId] = useState<number>();
    const [modalSiteId, setModalSiteId] = useState<number>();
    const [modalDefaultDate, setModalDefaultDate] = useState<Date>();
    const [showPlanningModal, setShowPlanningModal] = useState(false);
    const [blockedDates, setBlockedDates] = useState<Date[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const [
                applicationPeriodFetchResult,
                applicationsFetchResult,
                plannedApplicationSitesFetchResult,
                sitesFetchResult,
                planningCommentsFetchResult,
                availableSiteDatesFetchResult,
                blockedDatesFetchResult
            ] = await Promise.all([
                fetchApplicationPeriod(applicationPeriodId),
                fetchSelectedApplicationsByApplicationPeriodId(applicationPeriodId),
                fetchPlannedApplicationSitesByApplicationPeriodId(applicationPeriodId),
                fetchSitesWithPlannedOccasions(applicationPeriodId),
                fetchPlanningCommentsByApplicationPeriodId(applicationPeriodId),
                fetchAvailableSiteDatesByApplicationPeriodId(applicationPeriodId),
                fetchBlockedDatesByApplicationPeriodId(applicationPeriodId)
            ]);

            if (applicationPeriodFetchResult.errorMessage) {
                console.error(errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (applicationsFetchResult.errorMessage) {
                console.error(applicationsFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (plannedApplicationSitesFetchResult.errorMessage) {
                console.error(plannedApplicationSitesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (sitesFetchResult.errorMessage) {
                console.error(sitesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (planningCommentsFetchResult.errorMessage) {
                console.error(planningCommentsFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (availableSiteDatesFetchResult.errorMessage) {
                console.error(availableSiteDatesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (blockedDatesFetchResult.errorMessage) {
                console.error(blockedDatesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            sitesFetchResult.value.forEach((s) => {
                s.availableSiteDates = availableSiteDatesFetchResult.value.filter((asd) => asd.siteId === s.id);
            });

            applicationsFetchResult.value.forEach((a) => {
                const adjustedAgeTags = getAdjustedAgeTagsWithSelectedOccasions(a, false);
                a.groupedAgeTags = toGroupedAgeTags(adjustedAgeTags);
            });

            plannedApplicationSitesFetchResult.value.forEach((pas) => {
                pas.site = sitesFetchResult.value.find((s) => s.id === pas.siteId);
                pas.application = applicationsFetchResult.value.find((a) => a.id === pas.applicationId);
            });

            plannedApplicationSitesFetchResult.value = plannedApplicationSitesFetchResult.value.filter(
                (pas) => pas.application && pas.site
            );

            setApplicationPeriod(applicationPeriodFetchResult.value);
            setApplications(applicationsFetchResult.value);
            setSites(sitesFetchResult.value);
            setPlannedApplicationSites(sortPlannedApplicationSites(plannedApplicationSitesFetchResult.value));
            setPlanningComments(planningCommentsFetchResult.value);
            setBlockedDates(blockedDatesFetchResult.value);
            setIsLoading(false);
        };

        fetchData();
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (errorMessage) {
        return <ErrorMessage message={errorMessage} />;
    }

    if (applications.length === 0) {
        return (
            <>
                <header>
                    <h2>
                        Programläggning - evenemang: spelperiod {applicationPeriod.occasionsFrom.toLocaleDateString()}{" "}
                        till {applicationPeriod.occasionsTo.toLocaleDateString()}
                    </h2>
                </header>
                <InfoMessage message="Det finns inga evenemang från urvalet att visa." />
            </>
        );
    }

    const filterChangeHandler = (updatedProperty: keyof ApplicationPlanningFilterOptions, updatedValue: any) => {
        setSelectedFilterOptions((prevValue) => ({ ...prevValue, [updatedProperty]: updatedValue }));
    };

    const modalApplication = modalApplicationId ? applications.find((a) => a.id === modalApplicationId) : null;
    const modalSite = modalSiteId ? sites.find((s) => s.id === modalSiteId) : null;

    const plannedApplicationSiteAddedHandler = async (addedPAS: PlannedApplicationSite) => {
        const temporaryId = 0;
        addedPAS.id = temporaryId;

        setPlannedApplicationSites((prevValues) => sortPlannedApplicationSites([...prevValues, addedPAS]));

        const numberOfAddedOccasions = hasTwoOccasions(addedPAS) ? 2 : 1;

        const plannedDateWasAvailableForSite = modalSite.availableSiteDates.some(
            (asd) => asd.siteId === addedPAS.siteId && isSameDay(asd.availableDate, addedPAS.occasion1Starts)
        );

        if (plannedDateWasAvailableForSite) {
            const updatedSite: Site = {
                ...modalSite,
                remainingNumberOfAvailableSiteDates: modalSite.remainingNumberOfAvailableSiteDates - 1
            };

            setSites((prevValue) => prevValue.map((s) => (s.id === modalSiteId ? updatedSite : s)));
        }

        const updatedApplication: Application = {
            ...modalApplication,
            numberOfPlannedOccasions: modalApplication.numberOfPlannedOccasions + numberOfAddedOccasions
        };

        setApplications((prevValues) => prevValues.map((a) => (a.id === modalApplicationId ? updatedApplication : a)));

        const postResult = await postPlannedApplicationSite(addedPAS);

        if (postResult.errorMessage) {
            console.error(postResult.errorMessage);
            setErrorMessage(
                "Något gick fel när det programlagda tillfället sparades. Vänligen uppdatera sidan och försök igen. Kontakta support om problemet kvarstår."
            );
            return;
        }

        const postedPlannedApplicationSiteId = postResult.value;

        setPlannedApplicationSites((prevValues) =>
            prevValues.map((pas) => (pas.id === temporaryId ? { ...pas, id: postedPlannedApplicationSiteId } : pas))
        );
    };

    const plannedApplicationSiteRemovedHandler = async (removedPAS: PlannedApplicationSite) => {
        setPlannedApplicationSites((prevValues) =>
            prevValues.filter(
                (pas) =>
                    pas.siteId !== removedPAS.siteId ||
                    pas.applicationId !== removedPAS.applicationId ||
                    !isSameDay(pas.occasion1Starts, removedPAS.occasion1Starts)
            )
        );

        const numberOfRemovedOccasions = hasTwoOccasions(removedPAS) ? 2 : 1;
        const updatedApplication: Application = {
            ...modalApplication,
            numberOfPlannedOccasions: modalApplication.numberOfPlannedOccasions - numberOfRemovedOccasions
        };

        setApplications((prevValue) => prevValue.map((a) => (a.id === modalApplicationId ? updatedApplication : a)));

        const plannedDateWasAvailableForSite = modalSite.availableSiteDates.some(
            (asd) => asd.siteId === removedPAS.siteId && isSameDay(asd.availableDate, removedPAS.occasion1Starts)
        );

        if (plannedDateWasAvailableForSite) {
            const updatedSite: Site = {
                ...modalSite,
                remainingNumberOfAvailableSiteDates: modalSite.remainingNumberOfAvailableSiteDates + 1
            };

            setSites((prevValue) => prevValue.map((s) => (s.id === modalSiteId ? updatedSite : s)));
        }

        const deleteResult = await deletePlannedApplicationSite(removedPAS);

        if (deleteResult.errorMessage) {
            console.error(deleteResult.errorMessage);
            setErrorMessage(
                "Något gick fel när det programlagda tillfället skulle tas bort. Vänligen uppdatera sidan och försök igen. Kontakta support om problemet kvarstår."
            );
        }
    };

    const overviewDateClickHandler = (clickedDate: Date, clickedRowSiteId: number, clickedRowApplicationId: number) => {
        setModalDefaultDate(clickedDate);
        setModalSiteId(clickedRowSiteId);
        setModalApplicationId(clickedRowApplicationId);
        setShowPlanningModal(true);
    };

    let filteredApplications = applyApplicationPlanningFilter(
        applications,
        plannedApplicationSites,
        selectedFilterOptions
    );

    filteredApplications = sortApplicationsByRemainingToPlan(applications);

    const applicationTableRows = filteredApplications.map((a) => (
        <ApplicationTableRow application={a} />
    ));

    return (
        <>
            <div className="container mt-2">
                <header className="d-flex justify-content-between">
                    <h2>
                        Programläggning - evenemang: spelperiod {applicationPeriod.occasionsFrom.toLocaleDateString()}{" "}
                        till {applicationPeriod.occasionsTo.toLocaleDateString()}
                    </h2>
                </header>

                <Accordion>
                    <PlanningOverviewAccordionItem
                        plannedApplicationSites={plannedApplicationSites}
                        planningComments={planningComments}
                        applicationPeriod={applicationPeriod}
                        showExport={true}
                        onDateClick={overviewDateClickHandler}
                    />
                </Accordion>

                <ApplicationPlanningFilter
                    selectedFilterOptions={selectedFilterOptions}
                    onSelectedFilterOptionsChange={filterChangeHandler}
                    applicationPeriod={applicationPeriod}
                />
                <div className="border rounded">
                    <table className="table table-hover-effect ">
                        <thead>
                            <tr>
                                <th>Aktör</th>
                                <th>Evenemangsnamn</th>
                                <th>Målgrupp</th>
                                <th style={{ width: "175px" }}>Tillfällen totalt</th>
                                <th style={{ width: "175px" }}>Ej Programlagda</th>
                            </tr>
                        </thead>
                        <tbody>{applicationTableRows}</tbody>
                    </table>
                </div>
            </div>
            <PlanningModal
                application={modalApplication}
                site={modalSite}
                applicationPeriod={applicationPeriod}
                availableSiteDates={modalSite?.availableSiteDates ?? []}
                canManagePlanning={canManagePlanning}
                onAddPlannedApplicationSite={plannedApplicationSiteAddedHandler}
                onError={setErrorMessage}
                onHide={() => setShowPlanningModal(false)}
                onPlannedApplicationSitesChange={setPlannedApplicationSites}
                onPlanningCommentsChange={setPlanningComments}
                onRemovePlannedApplicationSite={plannedApplicationSiteRemovedHandler}
                plannedApplicationSites={plannedApplicationSites}
                planningComments={planningComments}
                show={showPlanningModal}
                defaultDate={modalDefaultDate}
                availableSiteTime={
                    modalSite && modalSite.availableSiteTimes.length > 0 ? modalSite.availableSiteTimes[0] : null
                }
                blockedDates={blockedDates}
            />
        </>
    );
}

export default ApplicationPlanning;
