import ErrorMessage from "../components/Common/Messages/ErrorMessage";
import AdjustedSelectionAgeTag from "../types/AdjustedSelectionAgeTag";
import Application from "../types/Application";
import ApplicationPeriod from "../types/ApplicationPeriod";
import CultureProvider from "../types/CultureProvider";
import PlannedApplicationSite from "../types/PlannedApplicationSite";
import PlanningComment from "../types/PlanningComment";
import Result from "../types/Result";
import SelectionComment from "../types/SelectionComment";
import Site from "../types/Site";
import { HexColor, TargetGroup } from "./enums";
import { pageLoadErrorMessage } from "./errorMessages";

export async function fetchApplicationPeriod(applicationPeriodId: number): Promise<Result<ApplicationPeriod>> {
    try {
        const fetchResult = await fetch(`/api/applicationPeriod/${applicationPeriodId}`);

        if (!fetchResult.ok) {
            const errorMessage = await fetchResult.text();
            return {
                errorMessage: errorMessage
            };
        }

        const fetchedApplicationPeriod: ApplicationPeriod = await fetchResult.json();

        fetchedApplicationPeriod.occasionsFrom = new Date(fetchedApplicationPeriod.occasionsFrom);
        fetchedApplicationPeriod.occasionsTo = new Date(fetchedApplicationPeriod.occasionsTo);

        return { value: fetchedApplicationPeriod };
    } catch (e) {
        return {
            errorMessage: e.message ? e.message : "Något gick fel när anmälningsperioden hämtades."
        };
    }
}

export async function fetchApplicationById(applicationId: number): Promise<Result<Application>> {
    try {
        const fetchResponse = await fetch(`/api/application/${applicationId}`);

        if (!fetchResponse.ok) {
            const errorMessage = await fetchResponse.text();

            return {
                errorMessage: errorMessage
            };
        }

        const application: Application = await fetchResponse.json();

        application.document.possibleOccasionDates = application.document.possibleOccasionDates.map((d) => new Date(d));

        return {
            value: application
        };
    } catch (error) {
        const errorMessage = error.message ? error.message : "Något gick fel när intresseanmälan hämtades.";

        return {
            errorMessage: errorMessage
        };
    }
}

export async function fetchViableApplicationsForSite(
    siteId: number,
    applicationPeriodId: number
): Promise<Result<Application[]>> {
    const defaultErrorMessage = `Något gick fel när evenemangen hämtades.`;

    const fetchResult = await fetchData<Application[]>(
        `/api/site/${siteId}/viableApplications/${applicationPeriodId}`,
        defaultErrorMessage
    );

    if (fetchResult.value) {
        fetchResult.value.forEach((a) => {
            const possibleOccasionDates: Date[] = [];
            a.document.possibleOccasionDates.forEach((dateString) => possibleOccasionDates.push(new Date(dateString)));
            a.document.possibleOccasionDates = possibleOccasionDates;
        });
    }

    return fetchResult;
}

export async function fetchApplicationsForActorContractCreation(
    applicationPeriodId: number,
    cultureProviderId: number
): Promise<Result<Application[]>> {
    const defaultErrorMessage = "Något gick fel när intresseanmälningarna hämtades.";

    const fetchResult = await fetchData<Application[]>(
        `/api/applicationPeriod/${applicationPeriodId}/cultureProvider/${cultureProviderId}/plannedApplications`,
        defaultErrorMessage
    );

    return fetchResult;
}

export const fetchAllSites = async (): Promise<Result<Site[]>> => {
    const defaultErrorMessage = "Något gick fel när spelplatserna hämtades.";

    try {
        const fetchResult = await fetch(`/api/sites`);

        if (!fetchResult.ok) {
            const errorMessage = (await fetchResult.text()) || defaultErrorMessage;
            return {
                errorMessage: errorMessage
            };
        }

        const fetchedSites: Site[] = await fetchResult.json();

        fetchedSites.forEach((s) => {
            s.availableSiteDates.forEach((sa) => (sa.availableDate = new Date(sa.availableDate)));
        });

        return { value: fetchedSites };
    } catch (error) {
        const errorMessage = error.message || defaultErrorMessage;

        return { errorMessage: errorMessage };
    }
};

export async function fetchViableSitesByApplicationId(applicationId: number): Promise<Result<Site[]>> {
    try {
        const fetchResponse = await fetch(`/api/application/${applicationId}/viableSites`);

        if (!fetchResponse.ok) {
            const errorMessage = await fetchResponse.text();
            return {
                errorMessage: errorMessage
            };
        }

        const sites: Site[] = await fetchResponse.json();

        return { value: sites };
    } catch (error) {
        const errorMessage = error.message
            ? error.message
            : `Något gick fel när lämpliga spelplatser för evenemang ${applicationId} hämtades.`;

        return {
            errorMessage: errorMessage
        };
    }
}

export async function fetchSiteById(siteId: number): Promise<Result<Site>> {
    try {
        const fetchResponse = await fetch(`/api/site/${siteId}`);

        if (!fetchResponse.ok) {
            let errorMessage = await fetchResponse.text();

            return {
                errorMessage: errorMessage
            };
        }

        const site = await fetchResponse.json();

        return {
            value: site
        };
    } catch (error) {
        const errorMessage = error.message ? error.message : `Något gick fel när spelplats ${siteId} hämtades.`;

        return {
            errorMessage: errorMessage
        };
    }
}

export async function fetchSelectedApplicationsByApplicationPeriodId(
    applicationPeriodId: number
): Promise<Result<Application[]>> {
    try {
        const fetchResult = await fetch(`/api/applicationPeriod/${applicationPeriodId}/applications/selected`);

        if (!fetchResult.ok) {
            const errorMessage = await fetchResult.text();
            return {
                errorMessage: errorMessage
            };
        }

        const selectedApplications = (await fetchResult.json()) as Application[];

        selectedApplications.forEach((a) => {
            a.document.possibleOccasionDates = a.document.possibleOccasionDates.map((pod) => new Date(pod));
        });

        return { value: selectedApplications };
    } catch (e) {
        return {
            errorMessage: e.message ? e.message : "Something went wrong when fetching the selected applications."
        };
    }
}

export async function fetchSitesByApplicationPeriodId(applicationPeriodId: number): Promise<Result<Site[]>> {
    try {
        const fetchResult = await fetch(`/api/applicationPeriod/${applicationPeriodId}/sites`);

        if (!fetchResult.ok) {
            const errorMessage = await fetchResult.text();
            return {
                errorMessage: errorMessage
            };
        }

        const sites = await fetchResult.json();
        return { value: sites };
    } catch (e) {
        return {
            errorMessage: e.message ? e.message : "Something went wrong when fetching the sites."
        };
    }
}

export async function fetchAvailableSiteDatesForSiteAndApplicationPeriod(
    siteId: number,
    applicationPeriodId: number
): Promise<Result<AvailableSiteDate[]>> {
    const defaultErrorMessage = "Något gick fel när spelplatsens tillgänglighet hämtades.";

    try {
        const fetchResponse = await fetch(`/api/site/${siteId}/availableSiteDate/${applicationPeriodId}`);

        if (!fetchResponse.ok) {
            const errorMessage = (await fetchResponse.text()) || defaultErrorMessage;

            return { errorMessage: errorMessage };
        }

        const availableSiteDates: AvailableSiteDate[] = await fetchResponse.json();

        availableSiteDates.forEach((sa) => (sa.availableDate = new Date(sa.availableDate)));

        return { value: availableSiteDates };
    } catch (error) {
        const errorMessage = error.message || defaultErrorMessage;

        return { errorMessage: errorMessage };
    }
}

export async function postAvailableSiteTime(availableSiteTime: AvailableSiteTime): Promise<Result<void>> {
    const defaultErrorMessage = "Något gick fel när spelplatsens tillgängliga tid uppdaterades.";

    const requestConfig: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            RequestVerificationToken: (document.getElementsByName("__RequestVerificationToken")[0] as HTMLInputElement)
                .value
        },
        body: JSON.stringify(availableSiteTime)
    };

    try {
        const postResponse = await fetch("/api/site/availableSiteTime/", requestConfig);

        if (!postResponse.ok) {
            const errorMessage = (await postResponse.text()) || defaultErrorMessage;

            return {
                errorMessage: errorMessage
            };
        }

        return {
            value: undefined
        };
    } catch (error) {
        const errorMessage = error.message || defaultErrorMessage;
        return {
            errorMessage: errorMessage
        };
    }
}

export async function fetchAvailableSiteDatesByApplicationPeriodId(
    applicationPeriodId: number
): Promise<Result<AvailableSiteDate[]>> {
    const apiEndpoint = `/api/applicationPeriod/${applicationPeriodId}/availableSiteDates`;
    const defaultErrorMessage = "Något gick fel när spelplatsernas tillgängliga datum hämtades.";

    const fetchResult = await fetchData<AvailableSiteDate[]>(apiEndpoint, defaultErrorMessage);

    if (fetchResult.value) {
        fetchResult.value.forEach((asd) => (asd.availableDate = new Date(asd.availableDate)));
    }

    return fetchResult;
}

export async function fetchAvailableSiteTimesByApplicationPeriodId(
    applicationPeriodId: number
): Promise<Result<AvailableSiteTime[]>> {
    const apiEndpoint = `/api/applicationPeriod/${applicationPeriodId}/availableSiteTimes`;
    const defaultErrorMessage = "Något gick fel när spelplatsernas tillgängliga tider hämtades.";

    const fetchResult = await fetchData<AvailableSiteTime[]>(apiEndpoint, defaultErrorMessage);

    return fetchResult;
}

export async function postPlannedApplicationSite(plannedApplicationSite: PlannedApplicationSite) {
    const defaultErrorMessage = "Något gick fel när evenemanget skulle programläggas på spelplatsen.";

    const pasToPost: PlannedApplicationSite = { ...plannedApplicationSite, application: null, site: null };

    const postResult = await postRequest<number>("/api/plannedApplicationSite", defaultErrorMessage, pasToPost);

    return postResult;
}

export async function deletePlannedApplicationSite(plannedApplicationSite: PlannedApplicationSite) {
    const defaultErrorMessage = "Något gick fel när det programlagda tillfället skulle tas bort.";

    const deleteResult = await deleteRequest(
        `/api/plannedApplicationSite/${plannedApplicationSite.id}`,
        defaultErrorMessage
    );

    return deleteResult;
}

export async function fetchPlannedApplicationSitesByApplicationId(
    applicationId: number
): Promise<Result<PlannedApplicationSite[]>> {
    const defaultErrorMessage = "Något gick fel när evenemangets programlagda tillfällen hämtades.";

    const fetchResult = await fetchData<PlannedApplicationSite[]>(
        `/api/application/${applicationId}/plannedApplicationSites`,
        defaultErrorMessage
    );

    if (fetchResult.value) {
        fetchResult.value.forEach((pas) => {
            pas.occasion1Starts = new Date(pas.occasion1Starts);
            pas.occasion1Ends = new Date(pas.occasion1Ends);
            pas.occasion2Starts = new Date(pas.occasion2Starts);
            pas.occasion2Ends = new Date(pas.occasion2Ends);
        });
    }

    return fetchResult;
}

export async function fetchPlannedApplicationSitesByApplicationPeriodId(
    applicationPeriodId: number
): Promise<Result<PlannedApplicationSite[]>> {
    const defaultErrorMessage = "Något gick fel när anmälningsperiodens programlagda tillfällen hämtades.";

    const fetchResult = await fetchData<PlannedApplicationSite[]>(
        `/api/applicationPeriod/${applicationPeriodId}/plannedApplicationSites`,
        defaultErrorMessage
    );

    if (fetchResult.value) {
        fetchResult.value.forEach((pas) => {
            pas.occasion1Starts = new Date(pas.occasion1Starts);
            pas.occasion1Ends = new Date(pas.occasion1Ends);
            pas.occasion2Starts = new Date(pas.occasion2Starts);
            pas.occasion2Ends = new Date(pas.occasion2Ends);
        });
    }

    return fetchResult;
}

export async function fetchPlannedApplicationSitesBySiteIdAndApplicationPeriodId(
    siteId: number,
    applicationPeriodId: number
): Promise<Result<PlannedApplicationSite[]>> {
    const defaultErrorMessage = "Något gick fel när spelplatsens programlagda tillfällen hämtades.";

    const fetchResult = await fetchData<PlannedApplicationSite[]>(
        `/api/site/${siteId}/applicationPeriod/${applicationPeriodId}/plannedApplicationSites`,
        defaultErrorMessage
    );

    if (fetchResult.value) {
        fetchResult.value.forEach((pas) => {
            pas.occasion1Starts = new Date(pas.occasion1Starts);
            pas.occasion1Ends = new Date(pas.occasion1Ends);
            pas.occasion2Starts = new Date(pas.occasion2Starts);
            pas.occasion2Ends = new Date(pas.occasion2Ends);
        });
    }

    return fetchResult;
}

export async function fetchPlannedApplicationSitesForActorContract(
    cultureProviderId: number,
    applicationPeriodId: number
): Promise<Result<PlannedApplicationSite[]>> {
    const defaultErrorMessage = "Något gick fel när aktörens programlagda tillfällen hämtades.";

    const fetchResult = await fetchData<PlannedApplicationSite[]>(
        `/api/applicationPeriod/${applicationPeriodId}/cultureProvider/${cultureProviderId}/plannedApplicationSites`,
        defaultErrorMessage
    );

    if (fetchResult.value) {
        fetchResult.value.forEach((pas) => {
            pas.occasion1Starts = new Date(pas.occasion1Starts);
            pas.occasion1Ends = new Date(pas.occasion1Ends);
            pas.occasion2Starts = new Date(pas.occasion2Starts);
            pas.occasion2Ends = new Date(pas.occasion2Ends);
        });
    }

    return fetchResult;
}

export async function fetchAvailableSiteTimeBySiteIdAndApplicationPeriodId(
    siteId: number,
    applicationPeriodId: number
): Promise<Result<AvailableSiteTime | null>> {
    const defaultErrorMessage = "Något gick fel när spelplatsens tillgängliga tid hämtades.";
    try {
        const fetchResponse = await fetch(
            `/api/applicationPeriod/${applicationPeriodId}/site/${siteId}/availableSiteTime`
        );

        if (!fetchResponse.ok) {
            const errorMessage = (await fetchResponse.text()) || defaultErrorMessage;

            return {
                errorMessage: errorMessage
            };
        }

        let fetchedData: AvailableSiteTime;
        try {
            fetchedData = await fetchResponse.json();
        } catch {
            //
            // An exception is thrown if no value was present. This happens when no available time has been set for the Site.
            //
            return {
                value: {
                    siteId: siteId,
                    applicationPeriodId: applicationPeriodId,
                    availableFrom: "",
                    availableTo: ""
                }
            };
        }

        return {
            value: fetchedData
        };
    } catch (error) {
        const errorMessage = error.message || defaultErrorMessage;

        return {
            errorMessage: errorMessage
        };
    }
}

export async function fetchCultureProvidersForContractCreation(
    applicationPeriodId: number
): Promise<Result<CultureProvider[]>> {
    const defaultErrorMessage = "Något gick fel när aktörerna hämtades.";

    const fetchResult = await fetchData<CultureProvider[]>(
        `/api/applicationPeriod/${applicationPeriodId}/contractCreation/cultureProviders`,
        defaultErrorMessage
    );

    return fetchResult;
}

export async function fetchCultureProviderForContractCreation(
    applicationPeriodId: number,
    cultureProviderId: number
): Promise<Result<CultureProvider>> {
    const defaultErrorMessage = "Något gick fel när aktören hämtades.";

    const fetchResult = await fetchData<CultureProvider>(
        `/api/applicationPeriod/${applicationPeriodId}/cultureProvider/${cultureProviderId}/contractCreation`,
        defaultErrorMessage
    );

    return fetchResult;
}

export async function fetchPlannedApplications(
    siteId: number,
    applicationPeriodId: number
): Promise<Result<Application[]>> {
    const defaultErrorMessage = "Något gick fel när intresseanmälningarna hämtades.";

    const fetchResult = await fetchData<Application[]>(
        `/api/applicationPeriod/${applicationPeriodId}/site/${siteId}/plannedApplications`,
        defaultErrorMessage
    );

    return fetchResult;
}

export async function sendSelectionSummary(
    applicationPeriodId: number,
    numberOfApplications: number,
    numberOfActors: number,
    numberOfSelectedApplications: number,
    numberOfSelectedActors: number,
    numberOfSelectedOccasions: number,
    numberOfActorsWithOperationalSupport: number
) {
    const defaultErrorMessage = "Något gick fel när sammanfattningen efter urvalsprocessen skickades.";

    const requestBody = {
        numberOfApplications: numberOfApplications,
        numberOfActors: numberOfActors,
        numberOfSelectedApplications: numberOfSelectedApplications,
        numberOfSelectedActors: numberOfSelectedActors,
        numberOfSelectedOccasions: numberOfSelectedOccasions,
        numberOfActorsWithOperationalSupport: numberOfActorsWithOperationalSupport
    };

    const postResult = await postRequest(
        `/api/selection/${applicationPeriodId}/selectionSummary`,
        defaultErrorMessage,
        requestBody
    );

    return postResult;
}

export async function sendTestSelectionSummary(
    applicationPeriodId: number,
    numberOfApplications: number,
    numberOfActors: number,
    numberOfSelectedApplications: number,
    numberOfSelectedActors: number,
    numberOfSelectedOccasions: number,
    numberOfActorsWithOperationalSupport: number
) {
    const defaultErrorMessage = "Något gick fel när sammanfattningen efter urvalsprocessen skickades.";

    const requestBody = {
        numberOfApplications: numberOfApplications,
        numberOfActors: numberOfActors,
        numberOfSelectedApplications: numberOfSelectedApplications,
        numberOfSelectedActors: numberOfSelectedActors,
        numberOfSelectedOccasions: numberOfSelectedOccasions,
        numberOfActorsWithOperationalSupport: numberOfActorsWithOperationalSupport
    };

    const postResult = await postRequest(
        `/api/selection/${applicationPeriodId}/testSelectionSummary`,
        defaultErrorMessage,
        requestBody
    );

    return postResult;
}

export async function sendInternalSiteAgreement(siteId: number, applicationPeriodId: number, agreementHtml: string) {
    const defaultErrorMessage = "Något gick fel när överenskommelsen skickades.";

    const requestBody = {
        agreementHtml: agreementHtml
    };

    const postResult = await postRequest(
        `/api/applicationPeriod/${applicationPeriodId}/site/${siteId}/sendInternalSiteAgreement`,
        defaultErrorMessage,
        requestBody
    );

    return postResult;
}

export async function fetchSitesWithPlannedOccasions(applicationPeriodId: number): Promise<Result<Site[]>> {
    const defaultErrorMessage = "Något gick fel när spelplatserna hämtades.";

    const fetchResult = await fetchData<Site[]>(
        `/api/applicationPeriod/${applicationPeriodId}/sitesWithPlannedOccasions`,
        defaultErrorMessage
    );

    return fetchResult;
}

export async function fetchApplicationsWithPlannedOccasions(
    applicationPeriodId: number
): Promise<Result<Application[]>> {
    const defaultErrorMessage = "Något gick fel när intresseanmälningarna hämtades.";

    const fetchResult = await fetchData<Application[]>(
        `/api/applicationPeriod/${applicationPeriodId}/applicationsWithPlannedOccasions`,
        defaultErrorMessage
    );

    if (!fetchResult.errorMessage) {
        fetchResult.value.forEach((application) => {
            application.document.possibleOccasionDates = application.document.possibleOccasionDates.map(
                (pod) => new Date(pod)
            );
        });
    }

    return fetchResult;
}

export async function fetchSitesWithAvailableDates(applicationPeriodId: number) {
    const defaultErrorMessage = "Något gick fel när spelplatserna hämtades.";

    const fetchResult = await fetchData<Site[]>(
        `/api/applicationPeriod/${applicationPeriodId}/sitesWithAvailableDates`,
        defaultErrorMessage
    );

    return fetchResult;
}

export async function fetchBlockedDates(): Promise<Result<Date[]>> {
    const defaultErrorMessage = "Något gick fel när de blockerade datumen hämtades.";

    const fetchResult = await fetchData<string[]>("/api/blockedDate", defaultErrorMessage);

    if (fetchResult.errorMessage) {
        return { errorMessage: fetchResult.errorMessage };
    }

    const dates = fetchResult.value.map((dateString) => new Date(dateString));

    return { value: dates };
}

export async function fetchBlockedDatesByApplicationPeriodId(
    applicationPeriodId: number
): Promise<Result<Date[]>> {
    const defaultErrorMessage = "Något gick fel när de blockerade datumen hämtades.";
    const url = `/api/applicationPeriod/${applicationPeriodId}/blockedDates`;

    const fetchResult = await fetchData<string[]>(url, defaultErrorMessage);

    if (fetchResult.errorMessage) {
        return { errorMessage: fetchResult.errorMessage };
    }

    const dates = fetchResult.value.map((dateString) => new Date(dateString));

    return { value: dates };
}

export async function postBlockedDate(date: Date) {
    const defaultErrorMessage = "Något gick fel när det blockerade datumet skulle läggas till.";

    const postResult = await postRequest("/api/blockedDate", defaultErrorMessage, date);

    return postResult;
}

export async function deleteBlockedDate(date: Date) {
    const defaultErrorMessage = "Något gick fel när det blockerade datumet skulle tas bort.";

    const deleteResult = await deleteRequest(`/api/blockedDate/${date.toISOString()}`, defaultErrorMessage);

    return deleteResult;
}

export async function postAdjustedOccasionPrice(
    applicationId: number,
    adjustedOccasionPrice: number
): Promise<Result<void>> {
    const defaultErrorMessage = "Något gick fel när priset uppdaterades.";

    const postBody = {
        applicationId: applicationId,
        adjustedOccasionPrice: adjustedOccasionPrice
    };

    const postResult = await postRequest<void>("/api/application/adjustedOccasionPrice", defaultErrorMessage, postBody);

    return postResult;
}

export async function unlockPossibleOccasionDates(applicationId: number, openUntil: Date): Promise<Result<void>> {
    const defaultErrorMessage = "Något gick fel när möjliga datum för evenemanget låstes upp.";

    const requestBody = {
        openUntil: openUntil
    };

    const putResult = await putRequest<void>(
        `/api/application/${applicationId}/possible-occasion-dates/open`,
        defaultErrorMessage,
        requestBody
    );

    return putResult;
}

export async function lockPossibleOccasionDates(applicationId: number): Promise<Result<void>> {
    const defaultErrorMessage = "Något gick fel när möjliga datum för evenemanget stängdes.";

    const putResult = await putRequest<void>(
        `/api/application/${applicationId}/possible-occasion-dates/lock`,
        defaultErrorMessage
    );

    return putResult;
}

export async function postSelectionComment(selectionComment: SelectionComment): Promise<Result<void>> {
    const defaultErrorMessage = "Något gick fel när kommentaren sparades.";

    const postResult = await postRequest<void>(`/api/selectionComment`, defaultErrorMessage, selectionComment);

    return postResult;
}

export async function fetchSitesForSitePlanning(applicationPeriodId: number): Promise<Result<Site[]>> {
    const defaultErrorMessage = "Något gick fel när spelplatserna hämtades.";

    const fetchResult = await fetchData<Site[]>(
        `/api/applicationPeriod/${applicationPeriodId}/sitePlanning/sites`,
        defaultErrorMessage
    );

    if (!fetchResult.errorMessage) {
        fetchResult.value.forEach((site) => {
            site.plannedApplicationSites = site.plannedApplicationSites.map((pas) => ({
                ...pas,
                occasion1Starts: new Date(pas.occasion1Starts),
                occasion1Ends: new Date(pas.occasion1Ends),
                occasion2Starts: pas.occasion2Starts && new Date(pas.occasion2Starts),
                occasion2Ends: pas.occasion2Ends && new Date(pas.occasion2Ends),
                site: site
            }));

            site.availableSiteDates.forEach((asd) => (asd.availableDate = new Date(asd.availableDate)));
        });
    }

    return fetchResult;
}

export async function postApplicationSelectionColor(
    hexColor: HexColor,
    applicationId: number,
    targetGroup: TargetGroup,
    isCulturalManagement: boolean
): Promise<Result<number>> {
    const defaultErrorMessage = "Något gick fel när färgmarkeringen sparades.";

    const postBody = {
        applicationId: applicationId,
        hexColor: hexColor,
        targetGroup: targetGroup,
        isCulturalManagement: isCulturalManagement
    };

    const postResult = await postRequest<number>(`/api/applicationSelectionColor`, defaultErrorMessage, postBody);

    return postResult;
}

export async function deleteApplicationSelectionColor(selectionColorId: number): Promise<Result<void>> {
    const defaultErrorMessage = "Något gick fel när färgmarkerings togs bort";

    const deleteResult = await deleteRequest(`/api/applicationSelectionColor/${selectionColorId}`, defaultErrorMessage);

    return deleteResult;
}

export async function postApplicationTargetGroupExclusion(
    applicationId: number,
    targetGroup: TargetGroup,
    isCulturalManagement: boolean
): Promise<Result<number>> {
    const defaultErrorMessage = "Något gick fel när intresseanmälan doldes.";

    const postBody = {
        applicationId: applicationId,
        targetGroup: targetGroup,
        isCulturalManagement: isCulturalManagement
    };

    const postResult = await postRequest<number>(`/api/applicationTargetGroupExclusion`, defaultErrorMessage, postBody);

    return postResult;
}

export async function deleteApplicationTargetGroupExclusion(
    applicationTargetGroupExclusionId: number
): Promise<Result<void>> {
    const defaultErrorMessage = "Något gick fel när intresseanmälan visades.";

    const deleteResult = await deleteRequest(
        `/api/applicationTargetGroupExclusion/${applicationTargetGroupExclusionId}`,
        defaultErrorMessage
    );

    return deleteResult;
}

export async function postPlanningComment(planningComment: PlanningComment): Promise<Result<number>> {
    const defaultErrorMessage = "Något gick fel när kommentaren sparades.";

    const body = {
        applicationId: planningComment.applicationId,
        siteId: planningComment.siteId,
        comment: planningComment.comment,
        date: planningComment.date
    };

    const postResult = await postRequest<number>(`/api/planningComment`, defaultErrorMessage, body);

    return postResult;
}

export async function deletePlanningComment(planningCommentId: number): Promise<Result<void>> {
    const defaultErrorMesage = "Något gick fel när kommenteran togs bort.";

    const deleteResult = await deleteRequest(`/api/planningComment/${planningCommentId}`, defaultErrorMesage);

    return deleteResult;
}

export async function fetchPlanningCommentsByApplicationPeriodId(
    applicationPeriodId: number
): Promise<Result<PlanningComment[]>> {
    const defaultErrorMessage = "Något gick fel när kommentarerna hämtades.";

    const fetchResult = await fetchData<PlanningComment[]>(
        `/api/applicationPeriod/${applicationPeriodId}/planningComments`,
        defaultErrorMessage
    );

    if (!fetchResult.errorMessage) {
        fetchResult.value = fetchResult.value.map((pc) => ({ ...pc, date: new Date(pc.date) }));
    }

    return fetchResult;
}

export async function confirmPlannedDate(plannedApplicationSiteId: number): Promise<Result<void>> {
    const defaultErrorMessage = "Något gick fel när det programlagda datumet markerades som bekräftat.";

    const putResult = await putRequest<void>(
        `/api/plannedApplicationSite/${plannedApplicationSiteId}/date/confirm`,
        defaultErrorMessage
    );

    return putResult;
}

export async function unconfirmPlannedDate(plannedApplicationSiteId: number): Promise<Result<void>> {
    const defaultErrorMessage = "Något gick fel när det programlagda datumet markerades som ej bekräftat.";

    const putResult = await putRequest<void>(
        `/api/plannedApplicationSite/${plannedApplicationSiteId}/date/unconfirm`,
        defaultErrorMessage
    );

    return putResult;
}

export async function postAdjustedSelectionAgeTag(
    adjustedSelectionAgeTag: AdjustedSelectionAgeTag
): Promise<Result<number>> {
    const defaultErrorMessage = "Något gick fel när de justerade målgrupperna sparades";

    const postResult = await postRequest<number>(
        `/api/adjustedSelectionAgeTag`,
        defaultErrorMessage,
        adjustedSelectionAgeTag
    );

    return postResult;
}

export async function deleteAdjustedSelectionAgeTag(adjustedSelectionAgeTagId: number): Promise<Result<void>> {
    const defaultErrorMesage = "Något gick fel när de justerade målgrupperna sparades";

    const deleteResult = await deleteRequest(
        `/api/adjustedSelectionAgeTag/${adjustedSelectionAgeTagId}`,
        defaultErrorMesage
    );

    return deleteResult;
}

export async function resetAdjustedSelectionAgeTags(
    applicationId: number,
    targetGroup: TargetGroup,
    isCulturalManagement: boolean
): Promise<Result<void>> {
    const defaultErrorMessage = "Något gick fel när evenemangets målgrupper återställdes.";

    const postResult = await postRequest<void>(
        `/api/application/${applicationId}/adjustedSelectionAgeTags/reset`,
        defaultErrorMessage
    );

    return postResult;
}

async function fetchData<TValue>(apiEndpoint: string, defaultErrorMessage: string): Promise<Result<TValue>> {
    try {
        const fetchResponse = await fetch(apiEndpoint);

        if (!fetchResponse.ok) {
            const errorMessage = (await fetchResponse.text()) || defaultErrorMessage;

            return {
                errorMessage: errorMessage
            };
        }

        const fetchedData: TValue = await fetchResponse.json();

        return {
            value: fetchedData
        };
    } catch (error) {
        const errorMessage = error.message || defaultErrorMessage;

        return {
            errorMessage: errorMessage
        };
    }
}

async function postRequest<TResponse>(
    apiEndpoint: string,
    defaultErrorMessage: string,
    body: any = {}
): Promise<Result<TResponse>> {
    const requestConfig: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            RequestVerificationToken: (document.getElementsByName("__RequestVerificationToken")[0] as HTMLInputElement)
                .value
        },
        body: JSON.stringify(body)
    };

    try {
        const postResponse = await fetch(apiEndpoint, requestConfig);

        if (!postResponse.ok) {
            const errorMessage = (await postResponse.text()) || defaultErrorMessage;

            return {
                errorMessage: errorMessage
            };
        }

        let responseValue: TResponse;

        try {
            responseValue = await postResponse.json();
        } catch (e) {
            // The response did not contain a JSON-object
        }

        return {
            value: responseValue
        };
    } catch (error) {
        const errorMessage = error.message || defaultErrorMessage;

        return {
            errorMessage: errorMessage
        };
    }
}

async function putRequest<TResponse>(
    apiEndpoint: string,
    defaultErrorMessage: string,
    body: any = {}
): Promise<Result<TResponse>> {
    const requestConfig: RequestInit = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            RequestVerificationToken: (document.getElementsByName("__RequestVerificationToken")[0] as HTMLInputElement)
                .value
        },
        body: JSON.stringify(body)
    };

    try {
        const putResponse = await fetch(apiEndpoint, requestConfig);

        if (!putResponse.ok) {
            const errorMessage = (await putResponse.text()) || defaultErrorMessage;

            return {
                errorMessage: errorMessage
            };
        }

        let responseValue: TResponse;

        try {
            responseValue = await putResponse.json();
        } catch (e) {
            // The response did not contain a JSON-object
        }

        return {
            value: responseValue
        };
    } catch (error) {
        const errorMessage = error.message || defaultErrorMessage;

        return {
            errorMessage: errorMessage
        };
    }
}

async function deleteRequest(apiEndpoint: string, defaultErrorMessage: string): Promise<Result<void>> {
    const requestConfig: RequestInit = {
        method: "DELETE",
        headers: {
            RequestVerificationToken: (document.getElementsByName("__RequestVerificationToken")[0] as HTMLInputElement)
                .value
        }
    };

    try {
        const postResponse = await fetch(apiEndpoint, requestConfig);

        if (!postResponse.ok) {
            const errorMessage = (await postResponse.text()) || defaultErrorMessage;

            return {
                errorMessage: errorMessage
            };
        }

        return {
            value: undefined
        };
    } catch (error) {
        const errorMessage = error.message || defaultErrorMessage;

        return {
            errorMessage: errorMessage
        };
    }
}
