import React, { useEffect, useState } from "react";
import ApplicationPeriod from "../../../types/ApplicationPeriod";
import { pageLoadErrorMessage } from "../../../common/errorMessages";
import LoadingSpinner from "../../Common/LoadingSpinner";
import ErrorMessage from "../../Common/Messages/ErrorMessage";
import Site from "../../../types/Site";
import { applySitePlanningFilter } from "../../../common/siteUtils";
import SiteTableRow from "./SiteTableRow";
import InfoMessage from "../../Common/Messages/InfoMessage";
import {
    deletePlannedApplicationSite,
    fetchApplicationPeriod,
    fetchApplicationsWithPlannedOccasions,
    fetchAvailableSiteDatesByApplicationPeriodId,
    fetchBlockedDatesByApplicationPeriodId,
    fetchPlanningCommentsByApplicationPeriodId,
    fetchSitesForSitePlanning,
    postPlannedApplicationSite
} from "../../../common/apiClient";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import SitePlanningFilter, { SitePlanningFilterOptions } from "./SitePlanningFilter";
import PlanningOverviewAccordionItem from "../PlanningOverviewAccordionItem";
import PlannedApplicationSite from "../../../types/PlannedApplicationSite";
import PlanningComment from "../../../types/PlanningComment";
import { getAdjustedAgeTagsWithSelectedOccasions } from "../../../common/applicationUtils";
import { toGroupedAgeTags } from "../../../common/stringUtils";
import { hasTwoOccasions, sortPlannedApplicationSites } from "../../../common/planningUtils";
import PlanningModal from "../PlanningModal";
import Application from "../../../types/Application";
import { isSameDay } from "../../../common/dateUtils";

type SitePlanningProps = {
    applicationPeriodId: number;
    canManagePlanning: boolean;
};

function SitePlanning({ applicationPeriodId, canManagePlanning }: SitePlanningProps) {
    const [applicationPeriod, setApplicationPeriod] = useState<ApplicationPeriod>();
    const [sites, setSites] = useState<Site[]>([]);
    const [applications, setApplications] = useState<Application[]>([]);
    const [plannedApplicationSites, setPlannedApplicationSites] = useState<PlannedApplicationSite[]>([]);
    const [selectedFilterOptions, setFilter] = useState<SitePlanningFilterOptions>();
    const [errorMessage, setErrorMessage] = useState("");
    const [planningComments, setPlanningComments] = useState<PlanningComment[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [modalApplicationId, setModalApplicationId] = useState<number>();
    const [modalSiteId, setModalSiteId] = useState<number>();
    const [modalDefaultDate, setModalDefaultDate] = useState<Date>();
    const [showPlanningModal, setShowPlanningModal] = useState(false);
    const [blockedDates, setBlockedDates] = useState<Date[]>([])

    useEffect(() => {
        const fetchData = async () => {
            const [
                applicationPeriodFetchResult,
                sitesFetchResult,
                applicationsFetchResult,
                planningCommentsFetchResult,
                availableSiteDatesFetchResult,
                blockedDatesFetchResult
            ] = await Promise.all([
                fetchApplicationPeriod(applicationPeriodId),
                fetchSitesForSitePlanning(applicationPeriodId),
                fetchApplicationsWithPlannedOccasions(applicationPeriodId),
                fetchPlanningCommentsByApplicationPeriodId(applicationPeriodId),
                fetchAvailableSiteDatesByApplicationPeriodId(applicationPeriodId),
                fetchBlockedDatesByApplicationPeriodId(applicationPeriodId)
            ]);

            if (applicationPeriodFetchResult.errorMessage) {
                console.error(applicationPeriodFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (sitesFetchResult.errorMessage) {
                console.error(sitesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (applicationsFetchResult.errorMessage) {
                console.error(applicationsFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (planningCommentsFetchResult.errorMessage) {
                console.error(planningCommentsFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (availableSiteDatesFetchResult.errorMessage) {
                console.error(availableSiteDatesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (blockedDatesFetchResult.errorMessage) {
                console.error(blockedDatesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            applicationsFetchResult.value.forEach((a) => {
                const adjustedAgeTags = getAdjustedAgeTagsWithSelectedOccasions(a, false);
                a.groupedAgeTags = toGroupedAgeTags(adjustedAgeTags);
            });

            sitesFetchResult.value.forEach((site) => {
                site.availableSiteDates = availableSiteDatesFetchResult.value.filter((asd) => asd.siteId === site.id);
                site.plannedApplicationSites.forEach((pas) => {
                    pas.application = applicationsFetchResult.value.find(
                        (application) => application.id === pas.applicationId
                    );
                });
            });

            setApplicationPeriod(applicationPeriodFetchResult.value);
            setSites(sitesFetchResult.value);
            setApplications(applicationsFetchResult.value);
            setPlannedApplicationSites(
                sortPlannedApplicationSites(sitesFetchResult.value.flatMap((s) => s.plannedApplicationSites))
            );
            setPlanningComments(planningCommentsFetchResult.value);
            setBlockedDates(blockedDatesFetchResult.value);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (errorMessage) {
        return <ErrorMessage message={errorMessage} />;
    }

    if (sites.length === 0) {
        return (
            <>
                <header>
                    <h2>
                        Programläggning - spelplatser: spelperiod {applicationPeriod.occasionsFrom.toLocaleDateString()}{" "}
                        till {applicationPeriod.occasionsTo.toLocaleDateString()}
                    </h2>
                </header>
                <InfoMessage message="Det finns inga spelplatser kopplade till anmälningsperioden." />
            </>
        );
    }

    const selectedFilterOptionsChangeHandler = (
        updatedProperty: keyof SitePlanningFilterOptions,
        updatedValue: any
    ) => {
        setFilter((prevValue) => ({ ...prevValue, [updatedProperty]: updatedValue }));
    };

    const modalApplication = modalApplicationId ? applications.find((a) => a.id === modalApplicationId) : null;
    const modalSite = modalSiteId ? sites.find((s) => s.id === modalSiteId) : null;

    const plannedApplicationSiteAddedHandler = async (addedPAS: PlannedApplicationSite) => {
        const temporaryId = 0;
        addedPAS.id = temporaryId;

        setPlannedApplicationSites((prevValues) => sortPlannedApplicationSites([...prevValues, addedPAS]));

        const numberOfAddedOccasions = hasTwoOccasions(addedPAS) ? 2 : 1;

        const plannedDateWasAvailableForSite = modalSite.availableSiteDates.some(
            (asd) => asd.siteId === addedPAS.siteId && isSameDay(asd.availableDate, addedPAS.occasion1Starts)
        );

        if (plannedDateWasAvailableForSite) {
            const updatedSite: Site = {
                ...modalSite,
                remainingNumberOfAvailableSiteDates: modalSite.remainingNumberOfAvailableSiteDates - 1
            };

            setSites((prevValue) => prevValue.map((s) => (s.id === modalSiteId ? updatedSite : s)));
        }

        const updatedApplication: Application = {
            ...modalApplication,
            numberOfPlannedOccasions: modalApplication.numberOfPlannedOccasions + numberOfAddedOccasions
        };

        setApplications((prevValues) => prevValues.map((a) => (a.id === modalApplicationId ? updatedApplication : a)));

        const postResult = await postPlannedApplicationSite(addedPAS);

        if (postResult.errorMessage) {
            console.error(postResult.errorMessage);
            setErrorMessage(
                "Något gick fel när det programlagda tillfället sparades. Vänligen uppdatera sidan och försök igen. Kontakta support om problemet kvarstår."
            );
            return;
        }

        const postedPlannedApplicationSiteId = postResult.value;

        setPlannedApplicationSites((prevValues) =>
            prevValues.map((pas) => (pas.id === temporaryId ? { ...pas, id: postedPlannedApplicationSiteId } : pas))
        );
    };

    const plannedApplicationSiteRemovedHandler = async (removedPAS: PlannedApplicationSite) => {
        setPlannedApplicationSites((prevValues) =>
            prevValues.filter(
                (pas) =>
                    pas.siteId !== removedPAS.siteId ||
                    pas.applicationId !== removedPAS.applicationId ||
                    !isSameDay(pas.occasion1Starts, removedPAS.occasion1Starts)
            )
        );

        const numberOfRemovedOccasions = hasTwoOccasions(removedPAS) ? 2 : 1;
        const updatedApplication: Application = {
            ...modalApplication,
            numberOfPlannedOccasions: modalApplication.numberOfPlannedOccasions - numberOfRemovedOccasions
        };

        setApplications((prevValue) => prevValue.map((a) => (a.id === modalApplicationId ? updatedApplication : a)));

        const plannedDateWasAvailableForSite = modalSite.availableSiteDates.some(
            (asd) => asd.siteId === removedPAS.siteId && isSameDay(asd.availableDate, removedPAS.occasion1Starts)
        );

        if (plannedDateWasAvailableForSite) {
            const updatedSite: Site = {
                ...modalSite,
                remainingNumberOfAvailableSiteDates: modalSite.remainingNumberOfAvailableSiteDates + 1
            };

            setSites((prevValue) => prevValue.map((s) => (s.id === modalSiteId ? updatedSite : s)));
        }

        const deleteResult = await deletePlannedApplicationSite(removedPAS);

        if (deleteResult.errorMessage) {
            console.error(deleteResult.errorMessage);
            setErrorMessage(
                "Något gick fel när det programlagda tillfället skulle tas bort. Vänligen uppdatera sidan och försök igen. Kontakta support om problemet kvarstår."
            );
        }
    };

    const overviewDateClickHandler = (clickedDate: Date, clickedRowSiteId: number, clickedRowApplicationId: number) => {
        setModalDefaultDate(clickedDate);
        setModalSiteId(clickedRowSiteId);
        setModalApplicationId(clickedRowApplicationId);
        setShowPlanningModal(true);
    };

    const filteredSites = applySitePlanningFilter(sites, selectedFilterOptions);

    const siteTableRows = filteredSites.map((s) => (
        <SiteTableRow site={s} key={s.id} applicationPeriodId={applicationPeriodId} />
    ));

    return (
        <>
            <div className="container mt-2">
                <header className="d-flex justify-content-between">
                    <h2>
                        Programläggning - spelplatser: spelperiod {applicationPeriod.occasionsFrom.toLocaleDateString()}{" "}
                        till {applicationPeriod.occasionsTo.toLocaleDateString()}
                    </h2>
                    <a className="btn btn-success" href={`/api/applicationPeriod/${applicationPeriodId}/economicBasisExport`}>
                        <i className="bi bi-file-excel-fill me-1"></i>Ladda ner kostnadsunderlag
                    </a>
                </header>

                <Accordion>
                    <PlanningOverviewAccordionItem
                        plannedApplicationSites={plannedApplicationSites}
                        planningComments={planningComments}
                        applicationPeriod={applicationPeriod}
                        showExport={true}
                        onDateClick={overviewDateClickHandler}
                    />
                </Accordion>

                <SitePlanningFilter
                    selectedFilterOptions={selectedFilterOptions}
                    onSelectedFilterOptionsChange={selectedFilterOptionsChangeHandler}
                    applicationPeriod={applicationPeriod}
                />
                <div className="border rounded" style={{ overflow: "hidden" }}>
                    <table className="table table-hover-effect">
                        <thead>
                            <tr>
                                <th>Plats</th>
                                <th>Lokalnamn</th>
                                <th style={{ width: "190px" }}>
                                    <span className="me-1">Tillgängliga datum</span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                Det totala antalet datum som kan programläggas på spelplatsen.
                                            </Tooltip>
                                        }
                                    >
                                        <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                                    </OverlayTrigger>
                                </th>
                                <th style={{ width: "190px" }}>
                                    <span className="me-1">Ej programlagda</span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                Det återstående antalet datum som kan programläggas på spelplatsen.
                                            </Tooltip>
                                        }
                                    >
                                        <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                                    </OverlayTrigger>
                                </th>
                            </tr>
                        </thead>
                        <tbody>{siteTableRows}</tbody>
                    </table>
                </div>
            </div>
            <PlanningModal
                application={modalApplication}
                site={modalSite}
                applicationPeriod={applicationPeriod}
                availableSiteDates={modalSite?.availableSiteDates ?? []}
                availableSiteTime={
                    modalSite && modalSite.availableSiteTimes.length > 0 ? modalSite.availableSiteTimes[0] : null
                }
                canManagePlanning={canManagePlanning}
                onAddPlannedApplicationSite={plannedApplicationSiteAddedHandler}
                onError={setErrorMessage}
                onHide={() => setShowPlanningModal(false)}
                onPlannedApplicationSitesChange={setPlannedApplicationSites}
                onPlanningCommentsChange={setPlanningComments}
                onRemovePlannedApplicationSite={plannedApplicationSiteRemovedHandler}
                plannedApplicationSites={plannedApplicationSites}
                planningComments={planningComments}
                show={showPlanningModal}
                defaultDate={modalDefaultDate}
                blockedDates={blockedDates}
            />
        </>
    );
}

export default SitePlanning;
