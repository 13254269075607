import React from "react";
import PlannedApplicationSite from "../../types/PlannedApplicationSite";
import { tableCellStyles } from "../../common/inlineStyles";
import { formatTime, getTimeSpanString } from "../../common/dateUtils";
import { hasTwoOccasions } from "../../common/planningUtils";
import { SiteType } from "../../common/enums";

type SiteScheduleTableRowProps = {
    plannedApplicationSite: PlannedApplicationSite;
    availableSiteTime: AvailableSiteTime;
};

export default function SiteScheduleTableRow({ plannedApplicationSite, availableSiteTime }: SiteScheduleTableRowProps) {

    const application = plannedApplicationSite.application;
    const site = plannedApplicationSite.site;

    if (!application || !site) {
        return <tr></tr>;
    }

    let buildTime = "";
    let strikeTime = "";
    const libraryIsPossibleSiteType = application.document.possibleSiteTypes.includes("Library");
    const outdoorsIsPossibleSiteType = application.document.possibleSiteTypes.includes("Outdoors");
    const schoolIsPossibleSiteType = application.document.possibleSiteTypes.includes("School");

    if (site.siteType === "Library" && libraryIsPossibleSiteType) {
        buildTime = application.document.library_BuildTime;
        strikeTime = application.document.library_StrikeTime;
    } else if (site.siteType === "Outdoors" && outdoorsIsPossibleSiteType) {
        buildTime = application.document.outdoors_BuildTime;
        strikeTime = application.document.outdoors_StrikeTime;
    } else if (site.siteType === "School" && schoolIsPossibleSiteType) {
        buildTime = application.document.school_BuildTime;
        strikeTime = application.document.school_BuildTime;
    } else if (site.siteType !== "OwnStage") {
        //
        // If the actor hasn't specified a build- and strike time for the current site type, default to the first found values.
        //

        [buildTime, strikeTime] = libraryIsPossibleSiteType ? [application.document.library_BuildTime, application.document.library_StrikeTime]
            : outdoorsIsPossibleSiteType ? [application.document.outdoors_BuildTime, application.document.outdoors_StrikeTime] 
            : [application.document.school_BuildTime, application.document.school_StrikeTime];
    }

    return (
        <tr>
            <td style={tableCellStyles}>{plannedApplicationSite.occasion1Starts.toLocaleDateString()}</td>
            <td style={tableCellStyles}>{formatTime(buildTime)}</td>
            <td style={tableCellStyles}>{formatTime(strikeTime)}</td>
            <td style={tableCellStyles}>{plannedApplicationSite.application.document.eventName}</td>
            <td style={tableCellStyles}>
                {getTimeSpanString(plannedApplicationSite.occasion1Starts, plannedApplicationSite.occasion1Ends)}
            </td>
            <td style={tableCellStyles}>
                {hasTwoOccasions(plannedApplicationSite)
                    ? getTimeSpanString(plannedApplicationSite.occasion2Starts, plannedApplicationSite.occasion2Ends)
                    : ""}
            </td>
            <td style={tableCellStyles}>{plannedApplicationSite.application.actorName}</td>
            <td style={tableCellStyles}>
                {plannedApplicationSite.application.document.contactName} <br />
                {plannedApplicationSite.application.document.contactPhoneNumber} <br />
                {plannedApplicationSite.application.document.contactEmailAddress}
            </td>
        </tr>
    );
}
