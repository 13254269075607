import React from "react";
import Application from "../../types/Application";
import { getAdjustedAgeTagsWithSelectedOccasions, getTotalNumberOfOccasionsFromSelection } from "../../common/applicationUtils";
import { toGroupedAgeTags } from "../../common/stringUtils";
import { tableCellStyles } from "../../common/inlineStyles";

export type SelectedApplicationTableRowProps = {
    application: Application;
};

function SelectedApplicationTableRow({ application }: SelectedApplicationTableRowProps) {
    const adjustedAgeTags = getAdjustedAgeTagsWithSelectedOccasions(application);
    const groupedAgeTags = toGroupedAgeTags(adjustedAgeTags);

    const numberOfOccasions = getTotalNumberOfOccasionsFromSelection(application);

    return (
        <tr>
            <td style={tableCellStyles}>{application.document.eventName}</td>
            <td style={tableCellStyles}>{groupedAgeTags.join(", ")}</td>
            <td style={tableCellStyles}>{numberOfOccasions}</td>
        </tr>
    );
}

export default SelectedApplicationTableRow;
