import { Modal } from "bootstrap";
import React, { useEffect, useState } from "react";
import { SiteType } from "../../common/enums";
import ApplicationPeriod from "../../types/ApplicationPeriod";
import Site from "../../types/Site";
import LoadingSpinner from "../Common/LoadingSpinner";
import CalenderModal, { siteSelectionCalenderModalId } from "./CalenderModal";
import SiteTableRow from "./SiteTableRow";
import { pageLoadErrorMessage } from "../../common/errorMessages";
import {
    fetchAllSites,
    fetchApplicationPeriod,
    fetchBlockedDates,
    fetchBlockedDatesByApplicationPeriodId
} from "../../common/apiClient";

type HandleSitesProps = {
    applicationPeriodId: number;
};

const siteTypes = Object.entries(SiteType).filter((siteTypeEntry) => siteTypeEntry[1] !== SiteType.OwnStage);

function SiteSelection({ applicationPeriodId }: HandleSitesProps) {
    const [sites, setSites] = useState<Site[] | undefined>();
    const [errorMessage, setErrorMessage] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [selectedSiteType, setSelectedSiteType] = useState<SiteType>();
    const [applicationPeriod, setApplicationPeriod] = useState<ApplicationPeriod>();
    const [calenderSite, setCalenderSite] = useState<Site>();
    const [blockedDates, setBlockedDates] = useState<Date[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const [sitesFetchResult, applicationPeriodFetchResult, blockedDatesFetchResult] = await Promise.all([
                fetchAllSites(),
                fetchApplicationPeriod(applicationPeriodId),
                fetchBlockedDatesByApplicationPeriodId(applicationPeriodId)
            ]);

            if (sitesFetchResult.errorMessage) {
                console.error(sitesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                return;
            }

            if (applicationPeriodFetchResult.errorMessage) {
                console.error(applicationPeriodFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                return;
            }

            if (sitesFetchResult.value.length > 0) {
                setCalenderSite(sitesFetchResult.value[0]);
            }

            if (blockedDatesFetchResult.errorMessage) {
                console.error(blockedDatesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                return;
            }

            setSites(sitesFetchResult.value);
            setApplicationPeriod(applicationPeriodFetchResult.value);
            setBlockedDates(blockedDatesFetchResult.value);
        };

        fetchData();
    }, []);

    if (sites === undefined || !applicationPeriod) {
        return <LoadingSpinner />;
    }

    if (sites.length === 0) {
        return <div className="alert alert-info mt-4">Det finns inga spelplatser.</div>;
    }

    const siteChangeHandler = (updatedSite: Site) => {
        setSites((prevSites) => prevSites.map((s) => (s.id === updatedSite.id ? updatedSite : s)));

        if (calenderSite.id === updatedSite.id) {
            setCalenderSite(updatedSite);
        }
    };

    const calenderButtonClickHandler = (site: Site) => {
        setCalenderSite(site);

        const modal = new Modal(document.getElementById(siteSelectionCalenderModalId));
        modal.show();
    };

    let currentSites = sites;

    if (searchInput) {
        currentSites = currentSites.filter(
            (s) =>
                s.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                s.roomName.toLowerCase().includes(searchInput.toLowerCase())
        );
    }

    if (selectedSiteType) {
        currentSites = currentSites.filter((s) => (s.siteType as SiteType) === selectedSiteType);
    }

    const siteListItems = currentSites.map((s) => (
        <SiteTableRow
            site={s}
            key={s.id}
            applicationPeriodId={applicationPeriodId}
            onCalenderButtonClick={calenderButtonClickHandler}
        />
    ));

    const siteTypeOptions = siteTypes.map((siteTypeEntry) => (
        <option key={siteTypeEntry[0]} value={siteTypeEntry[0]}>
            {siteTypeEntry[1]}
        </option>
    ));

    siteTypeOptions.unshift(
        <option key="AllSiteTypes" value={""}>
            - Alla -
        </option>
    );

    return (
        <>
            {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : ""}
            <div className="d-flex justify-content-between align-items-center mb-2 mt-2">
                <h2>Tillgängliga spelplatser {applicationPeriod.name}</h2>

                <a
                    className="btn btn-success"
                    href={`/api/applicationPeriod/${applicationPeriod.id}/availableSites/contactInfo/export`}
                >
                    <i className="bi bi-file-excel-fill me-1"></i>
                    Exportera kontaktuppgifter
                </a>
            </div>
            <div className="d-flex mb-2">
                <div className="me-4">
                    <label className="form-label mb-0">Fritextsök</label>
                    <input
                        className="form-control"
                        type="text"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        placeholder="Sök på plats eller lokalnamn"
                        style={{ width: "16rem" }}
                    />
                </div>
                <div>
                    <label className="form-label mb-0">Typ av lokal</label>
                    <select
                        className="form-select"
                        value={selectedSiteType}
                        onChange={(e) => setSelectedSiteType(e.target.value as SiteType)}
                    >
                        {siteTypeOptions}
                    </select>
                </div>
            </div>
            <div className="card">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th style={{ width: "4rem" }}></th>
                            <th>Plats</th>
                            <th>Lokalnamn</th>
                            <th>Typ av lokal</th>
                            <th style={{ width: "13rem" }}>Antal tillgängliga datum</th>
                        </tr>
                    </thead>
                    <tbody>{siteListItems}</tbody>
                </table>
            </div>
            <CalenderModal
                onError={(message) => setErrorMessage(message)}
                applicationPeriod={applicationPeriod}
                site={calenderSite}
                onSiteUpdated={siteChangeHandler}
                blockedDates={blockedDates}
            />
        </>
    );
}

export default SiteSelection;
