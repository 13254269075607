import { ApplicationPlanningFilterOptions } from "../components/Planning/ApplicationPlanning/ApplicationPlanningFilter";
import { SiteApplicationSelectionFilterOptions } from "../components/Planning/SitePlanning/SiteApplicationSelectionFilter";
import Application from "../types/Application";
import ApplicationPeriod from "../types/ApplicationPeriod";
import ApplicationSelectionColor from "../types/ApplicationSelectionColor";
import ApplicationTargetGroupExclusion from "../types/ApplicationTargetGroupExclusion";
import PlannedApplicationSite from "../types/PlannedApplicationSite";
import { isSameDay } from "./dateUtils";
import { AgeTag, ApplicationPlanningStatus, SiteType, TargetGroup } from "./enums";
import { sum } from "./numberUtils";
import { hasTwoOccasions } from "./planningUtils";
import { toGroupedAgeTags } from "./stringUtils";

export const targetGroupNames = {
    [TargetGroup.PreSchool]: "Förskola",
    [TargetGroup.SchoolLower]: "Lågstadium",
    [TargetGroup.SchoolMiddle]: "Mellanstadium",
    [TargetGroup.SchoolUpper]: "Högstadium",
    [TargetGroup.HighSchool]: "Gymnasium",
    [TargetGroup.SpecialNeedsSchool]: "Anpassad skola",
    [TargetGroup.Other]: "Extern budget",
    [TargetGroup.Total]: "Total",
    [TargetGroup.DayCareSchool]: "Fritidshem",
    [TargetGroup.SFI]: "SFI"
};

export function getBudgetByTargetGroup(
    applicationPeriod: ApplicationPeriod,
    targetGroup: TargetGroup,
    isCulturalManagement = false
): number {
    let budget = 0;

    if (targetGroup === TargetGroup.PreSchool) {
        budget = isCulturalManagement ? applicationPeriod.preSchoolBudgetCM : applicationPeriod.preSchoolBudget;
    } else if (targetGroup === TargetGroup.SchoolLower) {
        budget = isCulturalManagement ? applicationPeriod.schoolLowerBudgetCM : applicationPeriod.schoolLowerBudget;
    } else if (targetGroup === TargetGroup.SchoolMiddle) {
        budget = isCulturalManagement ? applicationPeriod.schoolMiddleBudgetCM : applicationPeriod.schoolMiddleBudget;
    } else if (targetGroup === TargetGroup.SchoolUpper) {
        budget = isCulturalManagement ? applicationPeriod.schoolUpperBudgetCM : applicationPeriod.schoolUpperBudget;
    } else if (targetGroup === TargetGroup.HighSchool) {
        budget = isCulturalManagement ? applicationPeriod.highSchoolBudgetCM : applicationPeriod.highSchoolBudget;
    } else if (targetGroup === TargetGroup.SpecialNeedsSchool) {
        budget = isCulturalManagement
            ? applicationPeriod.specialNeedsSchoolBudgetCM
            : applicationPeriod.specialNeedsSchoolBudget;
    } else if (targetGroup === TargetGroup.Other) {
        budget = isCulturalManagement ? applicationPeriod.otherBudgetCM : applicationPeriod.otherBudget;
    }

    return budget;
}

export function getCurrentCostByTargetGroup(
    applications: Application[],
    targetGroup: TargetGroup,
    isCulturalManagement: boolean
): number {
    return applications
        .map((a) => getCostForEvent(a, getNumberOfOccasionsByTargetGroup(a, targetGroup, isCulturalManagement)))
        .reduce((a, b) => a + b, 0);
}

export function getRemainingBudgetByTargetGroup(
    applications: Application[],
    applicationPeriod: ApplicationPeriod,
    targetGroup: TargetGroup,
    isCulturalManagement: boolean
): number {
    const remainingBudget =
        getBudgetByTargetGroup(applicationPeriod, targetGroup) -
        getCurrentCostByTargetGroup(applications, targetGroup, isCulturalManagement);

    return remainingBudget;
}

export function getCurrentElementarySchoolCost(applications: Application[], isCulturalManagement: boolean): number {
    let currentCost = 0;

    applications.forEach((application) => {
        const numberOfOccasions =
            getNumberOfOccasionsByTargetGroup(application, TargetGroup.SchoolLower, isCulturalManagement) +
            getNumberOfOccasionsByTargetGroup(application, TargetGroup.SchoolMiddle, isCulturalManagement) +
            getNumberOfOccasionsByTargetGroup(application, TargetGroup.SchoolUpper, isCulturalManagement);

        const costForEvent = getCostForEvent(application, numberOfOccasions);
        currentCost += costForEvent;
    });

    return currentCost;
}

export function getTotalCostForEvents(applications: Application[], isCulturalManagement = false) {
    let totalCost = 0;

    applications.forEach((a) => {
        const numberOfOccasions = getTotalNumberOfOccasionsFromSelection(a, isCulturalManagement);
        totalCost += getCostForEvent(a, numberOfOccasions);
    });

    return totalCost;
}

export function getCostForEvent(application: Application, numberOfOccasions: number): number {
    if (application.adjustedOccasionPrice || application.adjustedOccasionPrice === 0) {
        return application.adjustedOccasionPrice * numberOfOccasions;
    }

    if (!application.document.isVisiting && !application.document.isOwnStage) {
        return 0;
    }

    if (application.document.isVisiting && application.document.isOwnStage) {
        const visitingCost = GetCostForVisitingEvent(application, numberOfOccasions);
        const ownStageCost = getCostForOwnStageEvent(application, numberOfOccasions);

        return Math.max(visitingCost, ownStageCost);
    }

    if (application.document.isVisiting) {
        return GetCostForVisitingEvent(application, numberOfOccasions);
    }

    return getCostForOwnStageEvent(application, numberOfOccasions);
}

export function getCostForOwnStageEvent(application: Application, numberOfOccasions: number): number {
    if (application.adjustedOccasionPrice || application.adjustedOccasionPrice === 0) {
        return application.adjustedOccasionPrice * numberOfOccasions;
    }

    const cost = application.document.numberOfSeats * application.document.ticketPrice * numberOfOccasions;
    return cost;
}

export function getCostForSelectedOwnStageEvents(applications: Application[]): number {
    const cost = sum(applications.map((a) => getCostForOwnStageEvent(a, getTotalNumberOfOccasionsFromSelection(a))));

    return cost;
}

export function GetCostForVisitingEvent(application: Application, numberOfOccasions: number): number {
    if (application.adjustedOccasionPrice || application.adjustedOccasionPrice === 0) {
        return application.adjustedOccasionPrice * numberOfOccasions;
    }

    let visitingCost;

    if (application.document.occasionPrice2) {
        //
        // Two occasions will be planned on the same date in as high extent as possible. On these dates, the second occasion has a reduced cost (occasionPrice2).
        // If there is an occasion that cannot be grouped on the same date as another (in which case numberOfOccasions % 2 === 1), then this occasion gets the regular cost per occasion.
        //
        const numberOfOccasionPairs = Math.floor(numberOfOccasions / 2);
        const numberOfSingleOccasions = numberOfOccasions % 2;

        visitingCost =
            numberOfOccasionPairs * (application.document.occasionPrice + application.document.occasionPrice2) +
            numberOfSingleOccasions * application.document.occasionPrice;
    } else {
        //
        // There is no reduced price for the second occasion on the same date.
        //
        visitingCost = numberOfOccasions * application.document.occasionPrice;
    }

    return visitingCost;
}

export function getNumberOfOccasionsByTargetGroup(
    application: Application,
    targetGroup: string,
    isCulturalManagement: boolean
): number {
    if (targetGroup === TargetGroup.PreSchool && !isCulturalManagement) return application.numberOfOccasionsPreSchool;
    else if (targetGroup === TargetGroup.PreSchool) return application.numberOfOccasionsPreSchoolCM;
    else if (targetGroup === TargetGroup.SchoolLower && !isCulturalManagement)
        return application.numberOfOccasionsSchoolLower;
    else if (targetGroup === TargetGroup.SchoolLower) return application.numberOfOccasionsSchoolLowerCM;
    else if (targetGroup === TargetGroup.SchoolMiddle && !isCulturalManagement)
        return application.numberOfOccasionsSchoolMiddle;
    else if (targetGroup === TargetGroup.SchoolMiddle) return application.numberOfOccasionsSchoolMiddleCM;
    else if (targetGroup === TargetGroup.SchoolUpper && !isCulturalManagement)
        return application.numberOfOccasionsSchoolUpper;
    else if (targetGroup === TargetGroup.SchoolUpper) return application.numberOfOccasionsSchoolUpperCM;
    else if (targetGroup === TargetGroup.HighSchool && !isCulturalManagement)
        return application.numberOfOccasionsHighSchool;
    else if (targetGroup === TargetGroup.HighSchool) return application.numberOfOccasionsHighSchoolCM;
    else if (targetGroup === TargetGroup.SpecialNeedsSchool && !isCulturalManagement)
        return application.numberOfOccasionsSpecialNeedsSchool;
    else if (targetGroup === TargetGroup.SpecialNeedsSchool) return application.numberOfOccasionsSpecialNeedsSchoolCM;
    else if (targetGroup === TargetGroup.Other && !isCulturalManagement) return application.numberOfOccasionsOther;
    else if (targetGroup === TargetGroup.Other) return application.numberOfOccasionsOtherCM;

    return 0;
}

export function updateNumberOfOccasionsByTargetGroup(
    application: Application,
    targetGroup: string,
    numberOfOccasions: number,
    isCulturalManagement: boolean
): Application {
    if (targetGroup === TargetGroup.PreSchool && !isCulturalManagement)
        return { ...application, numberOfOccasionsPreSchool: numberOfOccasions };
    else if (targetGroup === TargetGroup.PreSchool)
        return { ...application, numberOfOccasionsPreSchoolCM: numberOfOccasions };
    else if (targetGroup === TargetGroup.SchoolLower && !isCulturalManagement)
        return { ...application, numberOfOccasionsSchoolLower: numberOfOccasions };
    else if (targetGroup === TargetGroup.SchoolLower)
        return { ...application, numberOfOccasionsSchoolLowerCM: numberOfOccasions };
    else if (targetGroup === TargetGroup.SchoolMiddle && !isCulturalManagement)
        return { ...application, numberOfOccasionsSchoolMiddle: numberOfOccasions };
    else if (targetGroup === TargetGroup.SchoolMiddle)
        return { ...application, numberOfOccasionsSchoolMiddleCM: numberOfOccasions };
    else if (targetGroup === TargetGroup.SchoolUpper && !isCulturalManagement)
        return { ...application, numberOfOccasionsSchoolUpper: numberOfOccasions };
    else if (targetGroup === TargetGroup.SchoolUpper)
        return { ...application, numberOfOccasionsSchoolUpperCM: numberOfOccasions };
    else if (targetGroup === TargetGroup.HighSchool && !isCulturalManagement)
        return { ...application, numberOfOccasionsHighSchool: numberOfOccasions };
    else if (targetGroup === TargetGroup.HighSchool)
        return { ...application, numberOfOccasionsHighSchoolCM: numberOfOccasions };
    else if (targetGroup === TargetGroup.SpecialNeedsSchool && !isCulturalManagement)
        return { ...application, numberOfOccasionsSpecialNeedsSchool: numberOfOccasions };
    else if (targetGroup === TargetGroup.SpecialNeedsSchool)
        return { ...application, numberOfOccasionsSpecialNeedsSchoolCM: numberOfOccasions };
    else if (targetGroup === TargetGroup.Other && !isCulturalManagement)
        return { ...application, numberOfOccasionsOther: numberOfOccasions };
    else if (targetGroup === TargetGroup.Other) return { ...application, numberOfOccasionsOtherCM: numberOfOccasions };

    return { ...application };
}

export function getTotalNumberOfOccasionsFromSelection(application: Application, isCulturalManagement = false): number {
    if (isCulturalManagement) {
        return (
            application.numberOfOccasionsPreSchoolCM +
            application.numberOfOccasionsSchoolLowerCM +
            application.numberOfOccasionsSchoolMiddleCM +
            application.numberOfOccasionsSchoolUpperCM +
            application.numberOfOccasionsHighSchoolCM +
            application.numberOfOccasionsSpecialNeedsSchoolCM +
            application.numberOfOccasionsOtherCM
        );
    }

    return (
        application.numberOfOccasionsPreSchool +
        application.numberOfOccasionsSchoolLower +
        application.numberOfOccasionsSchoolMiddle +
        application.numberOfOccasionsSchoolUpper +
        application.numberOfOccasionsHighSchool +
        application.numberOfOccasionsSpecialNeedsSchool +
        application.numberOfOccasionsOther
    );
}

export function getTotalBudget(applicationPeriod: ApplicationPeriod, isCulturalManagement = false): number {
    if (isCulturalManagement) {
        return (
            applicationPeriod.preSchoolBudgetCM +
            applicationPeriod.schoolLowerBudgetCM +
            applicationPeriod.schoolMiddleBudgetCM +
            applicationPeriod.schoolUpperBudgetCM +
            applicationPeriod.highSchoolBudgetCM +
            applicationPeriod.specialNeedsSchoolBudgetCM +
            applicationPeriod.otherBudgetCM
        );
    }

    return (
        applicationPeriod.preSchoolBudget +
        applicationPeriod.schoolLowerBudget +
        applicationPeriod.schoolMiddleBudget +
        applicationPeriod.schoolUpperBudget +
        applicationPeriod.highSchoolBudget +
        applicationPeriod.specialNeedsSchoolBudget +
        applicationPeriod.otherBudget
    );
}

export function getElementarySchoolBudget(applicationPeriod: ApplicationPeriod, isCulturalManagement = false): number {
    if (isCulturalManagement) {
        return (
            applicationPeriod.schoolLowerBudgetCM +
            applicationPeriod.schoolMiddleBudgetCM +
            applicationPeriod.schoolUpperBudgetCM
        );
    }

    return (
        applicationPeriod.schoolLowerBudget + applicationPeriod.schoolMiddleBudget + applicationPeriod.schoolUpperBudget
    );
}

export function getAdjustedAgeTagsByTargetGroup(
    application: Application,
    targetGroup: TargetGroup,
    isCulturalManagement: boolean
): AgeTag[] {
    let ageTags = getAgeTagsByTargetGroup(targetGroup).filter((a) => application.document.ageTags.includes(a));

    const adjustments = application.adjustedSelectionAgeTags
        ? application.adjustedSelectionAgeTags.filter(
              (a) => a.adjustedIn === targetGroup && a.isCulturalManagement === isCulturalManagement
          )
        : [];

    adjustments.forEach((a) => {
        if (a.isTargeted) {
            ageTags.push(a.ageTag as AgeTag);
        } else {
            ageTags = ageTags.filter((at) => at !== a.ageTag);
        }
    });

    return ageTags;
}

export function getGroupedAgeTags(
    application: Application,
    targetGroup: TargetGroup,
    isCulturalManagement: boolean
): string {
    const ageTags = getAdjustedAgeTagsByTargetGroup(application, targetGroup, isCulturalManagement);
    const groupedAgeTags = toGroupedAgeTags(ageTags).join(", ");

    return groupedAgeTags;
}

export function applicationBelongsToTargetGroup(
    application: Application,
    targetGroup: TargetGroup,
    isCulturalManagement: boolean
): boolean {
    let targetGroupAgeTags: string[] = [];

    if (targetGroup === TargetGroup.PreSchool) {
        targetGroupAgeTags = [AgeTag.Age3, AgeTag.Age4, AgeTag.Age5];
    } else if (targetGroup === TargetGroup.SchoolLower) {
        targetGroupAgeTags = [AgeTag.Year0, AgeTag.Year1, AgeTag.Year2, AgeTag.Year3];
    } else if (targetGroup === TargetGroup.SchoolMiddle) {
        targetGroupAgeTags = [AgeTag.Year4, AgeTag.Year5, AgeTag.Year6];
    } else if (targetGroup === TargetGroup.SchoolUpper) {
        targetGroupAgeTags = [AgeTag.Year7, AgeTag.Year8, AgeTag.Year9];
    } else if (targetGroup === TargetGroup.HighSchool) {
        targetGroupAgeTags = [
            AgeTag.HighSchoolYear1,
            AgeTag.HighSchoolYear2,
            AgeTag.HighSchoolYear3,
            AgeTag.HighSchoolYear4
        ];
    } else if (targetGroup === TargetGroup.SpecialNeedsSchool) {
        targetGroupAgeTags = [AgeTag.SpecialNeedsSchool, AgeTag.SpecialNeedsSchoolOld];
    } else if (targetGroup === TargetGroup.Other || targetGroup === TargetGroup.Total) {
        targetGroupAgeTags = Object.values(AgeTag);
    }

    const adjustedAgeTags = getAdjustedAgeTagsByTargetGroup(application, targetGroup, isCulturalManagement);

    return adjustedAgeTags.some((tag) => targetGroupAgeTags.includes(tag));
}

export function getAgeTagsByTargetGroup(targetGroup: TargetGroup): AgeTag[] {
    if (targetGroup === TargetGroup.PreSchool) {
        return [AgeTag.Age3, AgeTag.Age4, AgeTag.Age5];
    } else if (targetGroup === TargetGroup.SchoolLower) {
        return [AgeTag.Year0, AgeTag.Year1, AgeTag.Year2, AgeTag.Year3];
    } else if (targetGroup === TargetGroup.SchoolMiddle) {
        return [AgeTag.Year4, AgeTag.Year5, AgeTag.Year6];
    } else if (targetGroup === TargetGroup.SchoolUpper) {
        return [AgeTag.Year7, AgeTag.Year8, AgeTag.Year9];
    } else if (targetGroup === TargetGroup.HighSchool) {
        return [AgeTag.HighSchoolYear1, AgeTag.HighSchoolYear2, AgeTag.HighSchoolYear3, AgeTag.HighSchoolYear4];
    } else if (targetGroup === TargetGroup.SpecialNeedsSchool) {
        return [AgeTag.SpecialNeedsSchool];
    } else if (targetGroup === TargetGroup.Total || targetGroup === TargetGroup.Other) {
        return [
            AgeTag.Age3,
            AgeTag.Age4,
            AgeTag.Age5,
            AgeTag.Year0,
            AgeTag.Year1,
            AgeTag.Year2,
            AgeTag.Year3,
            AgeTag.Year4,
            AgeTag.Year5,
            AgeTag.Year6,
            AgeTag.Year7,
            AgeTag.Year8,
            AgeTag.Year9,
            AgeTag.HighSchoolYear1,
            AgeTag.HighSchoolYear2,
            AgeTag.HighSchoolYear3,
            AgeTag.HighSchoolYear4,
            AgeTag.SpecialNeedsSchool,
            AgeTag.LeisureCenter,
            AgeTag.SFI
        ];
    }

    return [];
}

export function getAdjustedAgeTagsWithSelectedOccasions(
    application: Application,
    isCulturalManagement = false
): AgeTag[] {
    let ageTags: AgeTag[] = [];

    const targetGroups = Object.values(TargetGroup);

    targetGroups.forEach((tg) => {
        const numberOfOccasionsForTargetGroup = getNumberOfOccasionsByTargetGroup(
            application,
            tg,
            isCulturalManagement
        );

        if (!numberOfOccasionsForTargetGroup) return;

        const adjustedAgeTagsForTargetGroup = getAdjustedAgeTagsByTargetGroup(application, tg, isCulturalManagement);

        ageTags = ageTags.concat(adjustedAgeTagsForTargetGroup);
    });

    ageTags = Array.from(new Set(ageTags));

    return ageTags;
}

export function getCostPerOccasionString(application: Application): string {
    if (application.adjustedOccasionPrice || application.adjustedOccasionPrice === 0) {
        return application.adjustedOccasionPrice.toLocaleString();
    }

    if (!application.document.isVisiting && !application.document.isOwnStage) {
        return "0";
    }

    let visitingCost = 0;
    let ownStageCost = 0;

    if (application.document.isVisiting) {
        if (application.document.occasionPrice2) {
            visitingCost = (application.document.occasionPrice + application.document.occasionPrice2) / 2;
        } else {
            visitingCost = application.document.occasionPrice;
        }
    }

    if (application.document.isOwnStage) {
        ownStageCost = application.document.ticketPrice * application.document.numberOfSeats;
    }

    if (visitingCost >= ownStageCost) {
        return `${application.document.occasionPrice.toLocaleString()} / ${application.document.occasionPrice2.toLocaleString()}`;
    }

    return ownStageCost.toLocaleString();
}

export function applyApplicationPlanningFilter(
    applications: Application[],
    plannedApplicationSites: PlannedApplicationSite[],
    filter: ApplicationPlanningFilterOptions
): Application[] {
    if (!filter) return applications;

    let filteredApplications = applications;

    if (filter.searchText) {
        const searchText = filter.searchText.toLowerCase();

        filteredApplications = filteredApplications.filter(
            (a) =>
                a.actorName.toLowerCase().includes(searchText) ||
                a.document.eventName.toLowerCase().includes(searchText)
        );
    }

    if (filter.applicationPlanningStatus === ApplicationPlanningStatus.HasNoOccasionsToPlan) {
        filteredApplications = filteredApplications.filter(
            (a) => getTotalNumberOfOccasionsFromSelection(a) - a.numberOfPlannedOccasions === 0
        );
    } else if (filter.applicationPlanningStatus === ApplicationPlanningStatus.HasOccasionsToPlan) {
        filteredApplications = filteredApplications.filter(
            (a) => getTotalNumberOfOccasionsFromSelection(a) - a.numberOfPlannedOccasions > 0
        );
    }

    if (filter.possibleOccasionDates && filter.possibleOccasionDates.length > 0) {
        filteredApplications = filteredApplications.filter((a) =>
            filter.possibleOccasionDates.some((filteredDate) =>
                a.document.possibleOccasionDates.some((pod) => isSameDay(filteredDate, pod))
            )
        );
    }

    return filteredApplications;
}

export function getRemainingNumberOfOccasionsToPlan(application: Application): number {
    const numberOfRemainingOccasionsToPlan =
        getTotalNumberOfOccasionsFromSelection(application) - application.numberOfPlannedOccasions;

    return numberOfRemainingOccasionsToPlan;
}

export function applySiteApplicationSelectionFilter(
    applications: Application[],
    filterOptions: SiteApplicationSelectionFilterOptions
): Application[] {
    if (!filterOptions) return applications;

    let filteredApplications = applications;

    if (filterOptions.searchText) {
        const lowerCaseText = filterOptions.searchText.toLowerCase();

        filteredApplications = filteredApplications.filter(
            (a) =>
                a.document.eventName.toLowerCase().includes(lowerCaseText) ||
                a.actorName.toLowerCase().includes(lowerCaseText)
        );
    }

    if (filterOptions.possibleOccasionDates && filterOptions.possibleOccasionDates.length > 0) {
        filteredApplications = filteredApplications.filter((a) =>
            filterOptions.possibleOccasionDates.some((filteredDate) =>
                a.document.possibleOccasionDates.some((pod) => isSameDay(filteredDate, pod))
            )
        );
    }

    return filteredApplications;
}

export function getTotalCostForPlannedApplicationSites(
    application: Application,
    plannedApplicationSites: PlannedApplicationSite[]
): number {
    const plannedApplicationSitesForApplication = plannedApplicationSites.filter(
        (pas) => pas.applicationId === application.id
    );

    if (application.adjustedOccasionPrice) {
        const totalCost = sum(
            plannedApplicationSitesForApplication.map((pas) =>
                hasTwoOccasions(pas) ? application.adjustedOccasionPrice * 2 : application.adjustedOccasionPrice
            )
        );

        return totalCost;
    }

    let totalCost = 0;

    plannedApplicationSitesForApplication.forEach((pas) => {
        if (SiteType[pas.site.siteType as keyof typeof SiteType] === SiteType.OwnStage) {
            totalCost += hasTwoOccasions(pas)
                ? 2 * application.document.ticketPrice * application.document.numberOfSeats
                : application.document.ticketPrice * application.document.numberOfSeats;
        } else if (pas.application.document.occasionPrice2) {
            totalCost += hasTwoOccasions(pas)
                ? application.document.occasionPrice + application.document.occasionPrice2
                : application.document.occasionPrice;
        } else {
            totalCost += hasTwoOccasions(pas)
                ? 2 * application.document.occasionPrice
                : application.document.occasionPrice;
        }
    });

    return totalCost;
}

export function getRelevantTargetGroupsByAgeTag(ageTag: AgeTag) {
    switch (ageTag) {
        case AgeTag.Age3:
        case AgeTag.Age4:
        case AgeTag.Age5:
            return [TargetGroup.PreSchool, TargetGroup.Other];

        case AgeTag.Year0:
        case AgeTag.Year1:
        case AgeTag.Year2:
        case AgeTag.Year3:
            return [TargetGroup.SchoolLower, TargetGroup.Other];

        case AgeTag.Year4:
        case AgeTag.Year5:
        case AgeTag.Year6:
            return [TargetGroup.SchoolMiddle, TargetGroup.Other];

        case AgeTag.Year7:
        case AgeTag.Year8:
        case AgeTag.Year9:
            return [TargetGroup.SchoolUpper, TargetGroup.Other];

        case AgeTag.HighSchoolYear1:
        case AgeTag.HighSchoolYear2:
        case AgeTag.HighSchoolYear3:
        case AgeTag.HighSchoolYear4:
            return [TargetGroup.HighSchool, TargetGroup.Other];

        case AgeTag.SpecialNeedsSchoolOld:
            return [TargetGroup.SpecialNeedsSchool, TargetGroup.Other];

        default:
            return [TargetGroup.Other];
    }
}

export function getSelectionCommentByTargetGroup(
    application: Application,
    targetGroup: TargetGroup,
    isCulturalManagement = false
): string {
    const selectionComment = application.selectionComments.find(
        (sc) => sc.targetGroup === targetGroup && sc.isCulturalManagement === isCulturalManagement
    );

    if (!selectionComment) return "";

    return selectionComment.comment;
}

export function getApplicationSelectionColorByTargetGroup(
    application: Application,
    targetGroup: TargetGroup,
    isCulturalManagement: boolean
): ApplicationSelectionColor | undefined {
    const applicationSelectionColor = application.applicationSelectionColors.find(
        (asc) => asc.targetGroup === targetGroup && asc.isCulturalManagement === isCulturalManagement
    );

    return applicationSelectionColor;
}

export function getApplicationTargetGroupExclusion(
    application: Application,
    targetGroup: TargetGroup,
    isCulturalManagement: boolean
): ApplicationTargetGroupExclusion | undefined {
    const applicationTargetGroupExclusion = application.applicationTargetGroupExclusions.find(
        (a) => a.targetGroup === targetGroup && a.isCulturalManagement === isCulturalManagement
    );

    return applicationTargetGroupExclusion;
}

export function sortApplicationsByRemainingToPlan(applications: Application[]): Application[] {
    const sorted = applications.sort(
        (a, b) => getRemainingNumberOfOccasionsToPlan(a) - getRemainingNumberOfOccasionsToPlan(b)
    );

    return sorted;
}

export function numberOfOccasionsRemainingToPlanColor(application: Application): string {
    const remainingToPlan = getRemainingNumberOfOccasionsToPlan(application);
    const backgroundColor = remainingToPlan === 0 ? "#8FD19F" : remainingToPlan < 0 ? "#EE969E" : "";

    return backgroundColor;
}
